import React from 'react';
import './invoice.css';

const InvoiceList = ({ invoices, onDelete, onDownload }) => {
  console.log("InsideTable", invoices);
  return (
    <div className="invoice-list">
      <div className="invoice-list-header">
        <div className="invoice-column col_1">Invoice Name</div>
        <div className="invoice-column col_2">Create Date</div>
        <div className="invoice-column col_3">Action</div>
      </div>
      {/* <div> <button className="btn blue-btn" onClick={() => onDownload(invoices.bucket_name, invoices.filename, invoices.s3path)}>Download</button></div> */}
      {invoices.map((invoice, index) => (
        <div className="invoice-list-item" key={index}>
          <div className="invoice-column L-H-34 col_1">{invoice.filename}</div>
          <div className="invoice-column L-H-34 col_2">{invoice.lastmodified}</div>
          <div className="invoice-column col_3">
            {/* <button className="btn blue-btn" onClick={() => onDelete(invoice.filename)}>Print</button> */}
            <button className="btn blue-btn" onClick={() => onDownload('pdfdownload1.pdf',invoice.filename)}>Download</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvoiceList;
