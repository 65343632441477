import React, { useState, useEffect } from 'react';

import Popup from 'reactjs-popup';

import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import ReactTooltip from 'react-tooltip';

import axios from 'axios';

import { useTranslation } from 'react-i18next';

import { PropTypes } from 'prop-types';

import { compose } from 'recompose';

import { connect } from 'react-redux';

import { Loader } from '../common/Loader/loader';

import { store } from 'react-notifications-component';

import { API_SERVER } from '../constant';

import {
  updateChartDataObject,
  resetChartDataObject,
} from '../../actions/DashboardAction/ChartDataAction';
import {
  updateChartTextObject,
  resetChartTextObject,
} from '../../actions/DashboardAction/ChartTextAction';
import {
  updateChartLegendObject,
  resetChartLegendObject,
} from '../../actions/DashboardAction/ChartLegendAction';
import {
  updateBarColorObject,
  resetBarColorObject,
} from '../../actions/DashboardAction/BarColorAction';
import {
  updateLineColorObject,
  resetLineColorObject,
} from '../../actions/DashboardAction/LineColorAction';

import './modal.css';
import ErrorMessageModal from './ErrorMessageModal';

import { DragSwitch } from 'react-dragswitch'
import 'react-dragswitch/dist/index.css'

const animatedComponents = makeAnimated();

const _ = require('lodash');

function ReportModal(props) {
  const { t, i18n } = useTranslation('common');

  let { showReportPopup, closeReportPopup, reportPopUpData, userEmail } = props;
  let [Loading, setLoading] = useState(false);

  let [reportFirstIndexList, setReportFirstIndexList] = useState([]);
  let [reportSecondIndexList, setReportSecondIndexList] = useState([]);
  let [reportThirdIndexList, setReportThirdIndexList] = useState([]);

  let [chartIndexList, setChartIndexList] = useState([
    { value: 'First', label: 'First Chart' },
    { value: 'Second', label: 'Second Chart' },
    { value: 'Third', label: 'Third Chart' },
  ]);

  let [selectedChartIndexItem, setSelectedChartIndexItem] = useState(null);
  let [reportIndexList, setReportIndexList] = useState([]);
  let [fileIndexList, setFileIndexList] = useState([]);
  let [selectedFileIndexItem, setSelectedFileIndexItem] = useState(null);
  let [selectedReportIndexItem, setSelectedReportIndexItem] = useState(null);

  let [selectedFirstFileIndexItem, setSelectedFirstFileIndexItem] =
    useState(null);
  let [selectedFirstReportIndexItem, setSelectedFirstReportIndexItem] =
    useState(null);

  let [selectedSecondFileIndexItem, setSelectedSecondFileIndexItem] =
    useState(null);
  let [selectedSecondReportIndexItem, setSelectedSecondReportIndexItem] =
    useState(null);

  let [selectedThirdFileIndexItem, setSelectedThirdFileIndexItem] =
    useState(null);
  let [selectedThirdReportIndexItem, setSelectedThirdReportIndexItem] =
    useState(null);

  let [selectedCategoryIndexItem, setSelectedCategoryIndexItem] = useState({
    value: 'ALL',
    label: 'ALL',
    key: 'ALL',
  });


  let [selectedSortParam, setSelectedSortParam] = useState('asc');
  let [selectedJsonSortParam, setSelectedJsonSortParam] = useState('asc');
  let [selectedCategorySortParam, setSelectedCategorySortParam] =
    useState('asc');

  const [showErrorFile, setShowErrorFile] = useState(false)
  let [showDownloadPopup, setShowDownloadPopup] = useState(false);
  let [zipLink, setZipLink] = useState(null);
  let [fileLink, setFileLink] = useState(null);

  let [showErrorMessagedPopup, setShowErrorMessagedPopup] = useState(false);
  let [selectedErrorFile, setSelectedErrorFile] = useState([]);
  let [selectedErrorLabel, setSelectedErrorLabel] = useState([]);

  const openErrorMessagePopup = () => {
    setShowErrorMessagedPopup((showErrorMessagedPopup = true));
  };

  const closeErrorMessagePopup = () => {
    setShowErrorMessagedPopup((showErrorMessagedPopup = false));
  };

  const openDownloadPopup = () => {
    setShowDownloadPopup((showDownloadPopup = true));
  };

  const closeDownloadPopup = () => {
    setShowDownloadPopup((showDownloadPopup = false));
  };

  function onChartIndexIndexChange(val) {
    
    setSelectedChartIndexItem((selectedChartIndexItem = val));

    setSelectedFileIndexItem((selectedFileIndexItem = null));
    setSelectedReportIndexItem((selectedReportIndexItem = null));
    setReportIndexList((reportIndexList = []));
    setShowErrorFile(showErrorFile)
    console.log("showErrorFile",showErrorFile);
  }

  function onFileIndexChange(val) {

    let fileDetails =val.label+'.txt';
       let userEmailID = props.userEmail.toLowerCase()
       let directoryDetails= val.Key;
       const splitString = val.label.split("-");
      console.log("splitString",splitString[0]);
       axios.post(API_SERVER +`s3-zip-file-get-content`,{'userEmail' :userEmailID ,'directoryName':directoryDetails ,"fileName":fileDetails})
       .then(function (response) {
         if(response.status === 200)
         {
           //const fileData = JSON.stringify(response.data);
           const fileData = response.data;
           console.log("fileData",fileData)
           let result = val.label.match(/ERROR/g);
            if(val.label.match(/ERROR/g)){
             setShowErrorMessagedPopup((showErrorMessagedPopup = true));
              setSelectedErrorFile((selectedErrorFile = fileData));
              setSelectedErrorLabel((selectedErrorLabel = t("ErrorFile."+splitString[0])));
              //setCategoryIndexList((categoryIndexList = []));
              setFileLink((fileLink = selectedFileIndexItem.zip_link.replaceAll('kpis-zip', "error-new-zip")));
            }
            
           
            const blob = new Blob([fileData], {type: "text/plain"});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = selectedReportIndexItem.label +".txt";
            link.href = url;
            link.click();
         }
         else
         {

         }
       })
       .catch(function (error) {

       })
       .then(function () {
       //  setLoading(false);
       });

    setSelectedFileIndexItem((selectedFileIndexItem = val));
    setSelectedReportIndexItem((selectedReportIndexItem = null));

    if (selectedChartIndexItem.value === 'First') {
      onFirstFileIndexChange(val);
    } else if (selectedChartIndexItem.value === 'Second') {
      onSecondFileIndexChange(val);
    } else if (selectedChartIndexItem.value === 'Third') {
      onThirdFileIndexChange(val);
    } else {
    }
  }

  function onReportIndexChange(val) {
    setSelectedReportIndexItem((selectedReportIndexItem = val));
    if (selectedChartIndexItem.value === 'First') {
      onFirstReportIndexChange(val);
    } else if (selectedChartIndexItem.value === 'Second') {
      onSecondReportIndexChange(val);
    } else if (selectedChartIndexItem.value === 'Third') {
      onThirdReportIndexChange(val);
    } else {
    }
  }

  function onFirstFileIndexChange(val) {
    setSelectedFirstFileIndexItem((selectedFirstFileIndexItem = val));
    if (selectedFirstFileIndexItem) {
      setReportFirstIndexList((reportFirstIndexList = []));
      setSelectedFirstReportIndexItem((selectedFirstReportIndexItem = null));

      let subFolderName = val.Key;
      let lastIndex = subFolderName.lastIndexOf('/');
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let directoryName = subFolderName_modified;

      getUploadedFileList(subFolderName, 'asc', 'fileIndexChange', 'first');
    } else {
      setReportFirstIndexList((reportFirstIndexList = []));
      setSelectedFirstReportIndexItem((selectedFirstReportIndexItem = null));
    }
  }

  function onFirstReportIndexChange(val) {
    setSelectedFirstReportIndexItem((selectedFirstReportIndexItem = val));
    console.log("onFirstReportIndexChange",val)
    if (val.type === 'settings') {
      getFileSettings(val, 'First');
    } else if (val.type === 'delete') {
      closeReportPopup();
    } else {
      let loadedSettings;
      props.onReportModalCloseAfterApi(
        selectedFirstFileIndexItem,
        selectedFirstReportIndexItem,
        loadedSettings,
        'First'
      );
      closeReportPopup();
    }
  }

  function onSecondFileIndexChange(val) {
    setSelectedSecondFileIndexItem((selectedSecondFileIndexItem = val));
    if (selectedSecondFileIndexItem) {
      setReportSecondIndexList((reportSecondIndexList = []));
      setSelectedSecondReportIndexItem((selectedSecondReportIndexItem = null));

      let subFolderName = val.Key;
      let lastIndex = subFolderName.lastIndexOf('/');
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let directoryName = subFolderName_modified;

      getUploadedFileList(subFolderName, 'asc', 'fileIndexChange', 'second');
    } else {
      setReportSecondIndexList((reportSecondIndexList = []));
      setSelectedSecondReportIndexItem((selectedSecondReportIndexItem = null));
    }
  }

  function onSecondReportIndexChange(val) {
    setSelectedSecondReportIndexItem((selectedSecondReportIndexItem = val));
    if (val.type === 'settings') {
      getFileSettings(val, 'Second');
    } else if (val.type === 'delete') {
      closeReportPopup();
    } else {
      let loadedSettings;
      props.onReportModalCloseAfterApi(
        selectedSecondFileIndexItem,
        selectedSecondReportIndexItem,
        loadedSettings,
        'Second'
      );
      closeReportPopup();
    }
  }

  function onThirdFileIndexChange(val) {
    setSelectedThirdFileIndexItem((selectedThirdFileIndexItem = val));
    if (selectedThirdFileIndexItem) {
      setReportThirdIndexList((reportThirdIndexList = []));
      setSelectedThirdReportIndexItem((selectedThirdReportIndexItem = null));

      let subFolderName = val.Key;
      let lastIndex = subFolderName.lastIndexOf('/');
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let directoryName = subFolderName_modified;

      getUploadedFileList(subFolderName, 'asc', 'fileIndexChange', 'third');
    } else {
      setReportThirdIndexList((reportThirdIndexList = []));
      setSelectedThirdReportIndexItem((selectedThirdReportIndexItem = null));
    }
  }

  function onThirdReportIndexChange(val) {
    setSelectedThirdReportIndexItem((selectedThirdReportIndexItem = val));

    if (val.type === 'settings') {
      getFileSettings(val, 'Third');
    } else if (val.type === 'delete') {
      closeReportPopup();
    } else {
      let loadedSettings;
      props.onReportModalCloseAfterApi(
        selectedThirdFileIndexItem,
        selectedThirdReportIndexItem,
        loadedSettings,
        'Third'
      );
      closeReportPopup();
    }
  }

  async function getUploadedFileList(directoryName, sortOrder, operation, pos) {
    setLoading(true);
  
    console.log("getUploadedFileList",directoryName, sortOrder, operation)
    await axios
      .post(API_SERVER + `s3-file-read-complete-folder`, {
        userEmail: userEmail,
        sortOrder: 'asc',
        directoryName:''
      })
      .then(function (response) {
        console.log(response, '=test');
        if (response.status === 200) {
          console.log("getUploadedFileList",response.data.data)
          //setReportPopUpData((reportPopUpData = response.data.data));
    axios
      .post(API_SERVER + `s3-zip-file-read-with-category-list`, {
        userEmail: userEmail,
        directoryName: directoryName,
        sortOrder: sortOrder,
        categorySortOrder: selectedCategorySortParam,
        filterBy: selectedCategoryIndexItem,
        completeFiles: response.data.data,
        showErrorFileStatus: showErrorFile
      })
      .then(function (response1) {
        if (response1.status === 200) {
          let arrayData = response1.data.data;
          
          if (pos === 'first') {
            setReportFirstIndexList((reportFirstIndexList = arrayData));
          } else if (pos === 'second') {
            setReportSecondIndexList((reportSecondIndexList = arrayData));
          } else {
            setReportThirdIndexList((reportThirdIndexList = arrayData));
          }
          console.log("response1>>",response1.data.data)
          console.log("Report list data:",response1.data.data.report_list_data)
          setReportIndexList((reportIndexList = response1.data.data.report_list_data));
          setFileIndexList(fileIndexList=response1.data.data)
        } else {
          store.addNotification({
            title: 'Error',
            message: 'Error',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: 'Error',
          message: 'Error',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
        setLoading(false);
      });
    }
  })
  }

  async function getFileSettings(val, pos) {
    setLoading(true);

    axios
      .post(API_SERVER + `s3-get-chart-setting-file`, {
        userEmail: userEmail,
        indexObject: selectedFileIndexItem,
        reportObject: val,
      })
      .then(function (response) {
        if (response.status === 200) {
          store.addNotification({
            title: 'Success',
            message: 'Please wait while configure selected settings',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });

          let loadedSettings = response.data;
          props.onReportModalCloseAfterApi(
            selectedFileIndexItem,
            val,
            loadedSettings,
            pos
          );
          closeReportPopup();
        } else {
          store.addNotification({
            title: 'Error',
            message: 'Error',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: 'Error',
          message: 'Error',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .finally(function () {
        setLoading(false);
      });
  }

  function checkBackground(param) {
    switch (param) {
      case 'settings':
        return '#CAE4FF';
      case 'deleted':
        return '#ffcaca';
      default:
        return 'undefined';
    }
  }

  useEffect(() => {
    if (props.showReportPopup) {
      if (selectedFileIndexItem != null) {
        let subFolderName = selectedFileIndexItem.Key;
        let sortOrder = selectedJsonSortParam;

      //  getUploadedFileList(subFolderName, sortOrder, 'onRefocus');
        getUploadedFileList(subFolderName, 'asc', 'fileIndexChange', selectedChartIndexItem);
      }
    } else {
    }
    
  }, [props.showReportPopup]);

   //show error files in dropdown on toggle event 
   const handlerErrorFile = () => {
    setShowErrorFile(!showErrorFile)
   
    let directory = '';
      let sortOrder = selectedJsonSortParam;
      console.log("Inside handlerErrorFile ")
      //getUploadedFileList(directory, sortOrder, '');
      getUploadedFileList(directory, 'asc', 'fileIndexChange', selectedChartIndexItem);
   

  };
  
  return (
    <React.Fragment>
      <Popup open={props.showReportPopup} onClose={props.closeReportPopup}>
      <ErrorMessageModal
          showErrorMessagedPopup={showErrorMessagedPopup}
          closeErrorMessagePopup={closeErrorMessagePopup}
          selectedErrorFile={selectedErrorFile}
          selectedErrorLabel={selectedErrorLabel}
          fileLink={fileLink}
        />
        <div>
          <div className='modal is-active custom-model-popup'>
            <div className='modal-card'>
              <header className='modal-card-head'>
                <p className='modal-card-title'>
                  {t('Modal.SelectChartFileAndReport')}
                </p>
                <button
                  className='deleteBtn'
                  onClick={props.closeReportPopup}
                  aria-label='close'
                >
                  <i className='fa fa-times-circle'></i>
                </button>
              </header>
              <section className='modal-card-body'>
                <div className='container'>
                  <div className='upl-file-row'>
                  <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                {console.log("Dropdown option>>",props.reportPopUpData)}
                {console.log("Dropdown option from reportIndexList>>",reportIndexList)}
                <lable style={{"margin-top":"-2px"}}>{t('ChartOption.ShowErrorFile')}</lable> &nbsp; &nbsp; 
                <DragSwitch onColor="#cccccc" offColor="#66bb6a" checked={showErrorFile} onChange={(e) => handlerErrorFile()
                   } />
                  
                </div>
                    <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                      <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                        <div className='styled-select arrows'>
                          <Select
                            name='FILE'
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={chartIndexList}
                            onChange={onChartIndexIndexChange}
                            value={selectedChartIndexItem}
                            isClearable={false}
                            placeholder={t('Select.selectChartIndex')}
                            isMulti={false}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#CADBFF',
                                primary: '#CADBFF',
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                      <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                        <div className='styled-select arrows'>
                          {console.log("file list",fileIndexList,props.reportPopUpData)}
                          <Select
                            name='FILE'
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={fileIndexList.length > 0 ? fileIndexList:props.reportPopUpData}
                            onChange={onFileIndexChange}
                            value={selectedFileIndexItem}
                            isClearable={false}
                            isDisabled={selectedChartIndexItem === null}
                            placeholder={t('Select.sourceFile')}
                            isMulti={false}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#CADBFF',
                                primary: '#CADBFF',
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                      <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                        <div className='styled-select arrows'>
                          <Select
                            name='FILE'
                            closeMenuOnSelect={true}
                            options={reportIndexList}
                            onChange={onReportIndexChange}
                            value={selectedReportIndexItem}
                            isClearable={false}
                            placeholder={t('Select.report')}
                            isDisabled={selectedChartIndexItem === null}
                            isMulti={false}
                            isSearchable={true}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#CADBFF',
                                primary: '#CADBFF',
                              },
                            })}
                            styles={{
                              option: (styles, { data }) => {
                                return {
                                  ...styles,
                                  backgroundColor: checkBackground(data.type),
                                };
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
           {Loading && <Loader />}
        </div>
      </Popup>
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const chartDataObject = reducerObj.dashchartDataReducers.chartData;
  const barColorObject = reducerObj.dashbarColorReducers.barColor;
  const chartTextObject = reducerObj.dashchartTextReducers.textData;
  const chartLegendObject = reducerObj.dashchartLegendReducers.legendData;
  const lineColorObject = reducerObj.dashlineColorReducers.LineColor;
  const languageObject = reducerObj.translationReducers.langObject;
  const toolTipObject = reducerObj.toolTipReducers.toolTipObject;

  return {
    languageObject,
    toolTipObject,
    chartDataObject,
    barColorObject,
    chartTextObject,
    chartLegendObject,
    lineColorObject,
  };
};

export default compose(
  connect(mapStateToProps, {
    updateChartDataObject,
    resetChartDataObject,
    updateChartTextObject,
    resetChartTextObject,
    updateChartLegendObject,
    resetChartLegendObject,
    updateLineColorObject,
    resetLineColorObject,
    updateBarColorObject,
    resetBarColorObject,
  })
)(ReportModal);
