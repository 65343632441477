import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { connect } from "react-redux";

import { compose } from "recompose";

import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

import ReactTooltip from "react-tooltip";

import { updateTabObject, resetTabObject } from "../../actions/TabAction";

import uploadIcon from "../../assets/images/upload-1.jpg";

import arrowIcon from "../../assets/images/arrow-img1.jpg";

import analiticsImg from "../../assets/images/analitics-2.jpg";

import arrowImg from "../../assets/images/arrow-img1.jpg";

import viewEditImg from "../../assets/images/view-edit-3.jpg";

import backImg from "../../assets/images/back.png";
import csvIcon from "../../assets/images/csv_icon.png";
import xlsIcon from "../../assets/images/xls_icon.png";
import tabIcon from "../../assets/images/tab_iocn.png";
import clip1Png from "../../assets/images/clip-1.png";

import text01Png from "../../assets/images/text-img-01.png";
import prestoUploadImg from "../../assets/images/presto-upload.png";
import prestoArrowLeftPng from "../../assets/images/presto-arrow-left.png";
import text02Png from "../../assets/images/text-img-02.png";
import prestoAimlPng from "../../assets/images/presto-aiml.png";
import text03Png from "../../assets/images/text-img-03.png";
import prestoChartPng from "../../assets/images/presto-charts.png";
import prestoArrowFullPng from "../../assets/images/presto-arrow-full.png";
import clipViewChartPng from "../../assets/images/clip-view-chart.png";
import clipDashPng from "../../assets/images/clip-dash.png";
import clipDashboardPng from "../../assets/images/clip-dashboard.png";
import clipPivotPng from "../../assets/images/clip-pivot.png";
import SignUpModal from "../common/Header/SignUpModal"
import { updateLanguageObject, resetLanguageObject } from "../../actions/TranslationAction";

import { API_SERVER } from "../constant";

import "./home.css";

function OuterHome(props) {
  const { t, i18n } = useTranslation("common");
  let [menuTitle, setMenuTitle] = useState("");
  let [showSignUpPopup, setShowSignUpPopup] = useState(false);
  let [selectedItem, setSelectedItem] = useState(0);

  const closeSignUpPopup = () => {
    setSelectedItem(0);
    setShowSignUpPopup(showSignUpPopup = false);
  };

  let [show, setShow] = useState(false);

  useEffect(() => {
    if (selectedItem == 8) {
      setMenuTitle({ title: t('Header.MLAI-Logo'), promotionText: "Get 10 MB of free file processing" });
    }
  }, [selectedItem]);

  const handleHomepageItem = (item) => {
    setSelectedItem(item);
    setShowSignUpPopup(showSignUpPopup = true);
  };

  function toggleChartType() {
    setShow((show = !show));
  }

  function goToUploadFiles() {
    props.updateTabObject({ tabIndex: 1 });
  }

  function goToAnalytics() {
    props.updateTabObject({ tabIndex: 6 });
  }

  function goToViewChart() {
    props.updateTabObject({ tabIndex: 2 });
  }

  function goToCollection() {
    props.updateTabObject({ tabIndex: 4 });
  }

  function goToDashboard() {
    props.updateTabObject({ tabIndex: 3 });
  }

  function goToPrestoPivot() {
    props.updateTabObject({ tabIndex: 5 });
  }

  function goToLearnMore() { }


  return (
    <React.Fragment>
      <SignUpModal
        showSignUpPopup={showSignUpPopup}
        closeSignUpPopup={closeSignUpPopup}
        menuTitle={menuTitle}
        setMenuTitle={setMenuTitle}
      />
      <div className="midChatSectonBtn" style={{ display: show ? "none" : "" }}>
        <div className="midprocessSection">
          <div className="container">
            <div className="midprocesscont d-flex justify-content align-items">
              <div className="presto-upl">
                <ReactTooltip
                  id='securefileupload'
                  disable={props.toolTipObject.toolTip}
                  place='top'
                  delayHide={100}
                  effect='solid'
                >
                  Secure File Upload
                </ReactTooltip>
                <button
                data-tip
                data-for='securefileupload'
                  type="button"
                  className="btn"
                  onClick={(e) => {
                    goToUploadFiles(e);
                  }}
                >
                  <div className="text-top-tilte">
                    <img src={text01Png} alt="" className="img-fluid" />
                  </div>
                  <div className="dat-img">
                    <img src={prestoUploadImg} alt="" className="img-fluid " />
                  </div>
                </button>

              </div>

              <div className="presto-ar-left">
                <img src={prestoArrowLeftPng} alt="" className="img-fluid" />
              </div>

              <div className="presto-aiml">
                <button
                  type="button"
                  className="btn"
                  // onClick={(e) => {
                  //   goToAnalytics(e);
                  // }}
                  onClick={() => handleHomepageItem(8)}
                >
                  <div className="text-top-tilte">
                    <img src={text02Png} alt="" className="img-fluid" />
                  </div>
                  <div className="dat-img">
                    <img src={prestoAimlPng} alt="" className="img-fluid" onClick={() => handleHomepageItem(8)} />
                  </div>
                </button>
              </div>

              <div className="presto-ar-left">
                <img src={prestoArrowLeftPng} alt="" className="img-fluid" />
              </div>

              <div className="presto-charts">
              <ReactTooltip
                  id='dataCharts'
                  disable={props.toolTipObject.toolTip}
                  place='top'
                  delayHide={100}
                  effect='solid'
                >
                  See all the reports in charts
                </ReactTooltip>
                <button
                data-tip
                data-for='dataCharts'
                  type="button"
                  className="btn"
                  onClick={(e) => {
                    toggleChartType(e);
                  }}
                >
                  <div className="text-top-tilte">
                    <img src={text03Png} alt="" className="img-fluid" />
                  </div>
                  <div className="dat-img">
                    <img src={prestoChartPng} alt="" className="img-fluid" />
                  </div>
                </button>
              </div>
            </div>
            <div className="presto-ar-wrap">
              <div className="text-bottom-tilte">
                {" "}
                {t("Header.viewEditSavePublish")}{" "}
              </div>
              <div className="presto-ar-full">
                <img src={prestoArrowFullPng} alt="" />
              </div>
            </div>
            <div className="botprocessSection d-flex justify-content align-items">
              <div className="card-view">
                <div className="dat-img">
                  <img src={clipViewChartPng} alt="" className="img-fluid" />
                  <div className="btn-footer">
                    <button
                      type="button"
                      onClick={(e) => {
                        goToViewChart(e);
                      }}
                      className="btn-orange link-button"
                    >
                      {t("Header.chartIt")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-view">
                <div className="dat-img">
                  <img src={clipDashPng} alt="" className="img-fluid " />
                  <div className="btn-footer">
                    <button
                      type="button"
                      onClick={(e) => {
                        goToDashboard(e);
                      }}
                      className="btn-orange link-button"
                    >
                      {t("Header.dashboard")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-view">
                <div className="dat-img">
                  <img src={clipDashboardPng} alt="" className="img-fluid " />
                  <div className="btn-footer">
                    <button
                      type="button"
                      onClick={(e) => {
                        goToCollection(e);
                      }}
                      className="btn-orange link-button"
                    >
                      {t("Header.collection")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-view">
                <div className="dat-img">
                  <img src={clipPivotPng} alt="" className="img-fluid " />
                  <div className="btn-footer">
                    <button
                      type="button"
                      onClick={(e) => {
                        goToPrestoPivot(e);
                      }}
                      className="btn-orange link-button"
                    >
                      {t("Header.pivot")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip
        id="back"
        disable={props.toolTipObject.toolTip}
        place="left"
        delayHide={100}
        effect="float"
      >
        {t("Tooltip.back")}
      </ReactTooltip>
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const tabObject = reducerObj.tabReducers.tabObject;
  const languageObject = reducerObj.translationReducers.langObject;
  const toolTipObject = reducerObj.toolTipReducers.toolTipObject;
  return { tabObject, languageObject, toolTipObject };
};

export default compose(
  connect(mapStateToProps, {
    updateTabObject,
    resetTabObject,
  })
)(OuterHome);
