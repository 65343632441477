import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import { connect } from 'react-redux';

import { PropTypes } from 'prop-types';

import { compose } from 'recompose';

import html2canvas from 'html2canvas';

import ReactTooltip from 'react-tooltip';
import { DownOutlined, UserOutlined, PlusCircleOutlined, SettingOutlined, QuestionCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';

import { Auth } from 'aws-amplify';

import { Loader } from '../common/Loader/loader';

import { store } from 'react-notifications-component';

import { API_SERVER } from '../constant';

import Select from 'react-select';

import Draggable from 'react-draggable';

import { useTranslation } from 'react-i18next';

import makeAnimated from 'react-select/animated';
import { checkUserSession } from '../common/Helper/helper';
import HelpModal from './HelpModal';

import FBarChart from './chartComponents/FirstBar/barChart';
import SBarChart from './chartComponents/SecondBar/barChart';
import TBarChart from './chartComponents/ThirdBar/barChart';
import ChartIndex from './settingComponents/chartIndex';

import {
  updateChartDataObject,
  resetChartDataObject,
} from '../../actions/DashboardAction/ChartDataAction';
import {
  updateChartTextObject,
  resetChartTextObject,
} from '../../actions/DashboardAction/ChartTextAction';
import {
  updateChartLegendObject,
  resetChartLegendObject,
} from '../../actions/DashboardAction/ChartLegendAction';
import {
  updateBarColorObject,
  resetBarColorObject,
} from '../../actions/DashboardAction/BarColorAction';
import {
  updateLineColorObject,
  resetLineColorObject,
} from '../../actions/DashboardAction/LineColorAction';
import {
  updateChartBaseObject,
  resetChartBaseObject,
} from '../../actions/DashboardAction/ChartBaseAction';

import {
  updateChartSettingObject,
  resetChartSettingObject,
} from '../../actions/DashboardAction/ChartSettingsAction';
import {
  updateChartTypeObject,
  resetChartTypeObject,
} from '../../actions/DashboardAction/ChartTypeAction';
import {
  updateDashboardTitleObject,
  resetDashboardTitleObject,
} from '../../actions/DashboardAction/ChartTitleAction';

import ReportModal from './ReportModal';
import PrintModal from './PrintModal';

import saveSVG from '../../assets/images/save.svg';

import settingIcon from '../../assets/images/setting.png';

import TextBase from './dashboardComponents/textBase';

import './dashboard.css';
import plusSignBlue from "../../assets/images/PlusSign_blue.png";

const animatedComponents = makeAnimated();

const _ = require('lodash');

const pdfConverter = require('jspdf');

function Dashboard(props) {
  const { t, i18n } = useTranslation('common');

  let [Loading, setLoading] = useState(false);
  let [expand, setExpanded] = useState(false);
  let [show, setShow] = useState(false);
  let [userEmail, setUserEmail] = useState(null);
  let [reportPopUpData, setReportPopUpData] = useState([]);

  let [firstChartHeader, setFirstChartHeader] = useState(null);
  let [secondChartHeader, setSecondChartHeader] = useState(null);
  let [thirdChartHeader, setThirdChartHeader] = useState(null);

  let [flabels, setfLabels] = useState([]);
  let [fgraphData, setfGraphData] = useState([]);
  let [fcolors, setfColors] = useState([]);
  let [xAxisfLabel, setXaxisfLabel] = useState(null);
  let [yAxisfLabel, setYaxisfLabel] = useState(null);
  let [fcompleteArray, setfCompleteArray] = useState([]);

  let [dlabels, setdLabels] = useState([]);
  let [dgraphData, setdGraphData] = useState([]);
  let [dcolors, setdColors] = useState([]);
  let [xAxisdLabel, setXaxisdLabel] = useState(null);
  let [yAxisdLabel, setYaxisdLabel] = useState(null);
  let [dcompleteArray, setdCompleteArray] = useState([]);

  let [tlabels, settLabels] = useState([]);
  let [tgraphData, settGraphData] = useState([]);
  let [tcolors, settColors] = useState([]);
  let [xAxistLabel, setXaxistLabel] = useState(null);
  let [yAxistLabel, setYaxistLabel] = useState(null);
  let [tcompleteArray, settCompleteArray] = useState([]);

  let [showReportPopup, setShowReportPopup] = useState(false);

  let [showPrintPopup, setShowPrintPopup] = useState(false);
  let [printPopUpData, setPrintPopUpData] = useState(null);

  let [showHelpPopup, setShowHelpPopup] = useState(false);

  function toggleSidebar() {
    setExpanded((expand = !expand));
  }

  function toggleGraph() {
    setShow((show = !show));
  }

  const handleMenuClick = (e) => {
    //message.info('Click on menu item.');
    console.log('click', e);
    if(e.key=="openreport"){
      openReportPopup();
    } else if(e.key=="formatreport"){
      toggleGraph();
    }
    else if(e.key=="download"){
      openPrintPopup();
    }
    else if(e.key=="help"){
      openHelpPopup();
    }
    
    
  };
  const items = [
    {
      label: t('ChartOption.Select_Chart'),
      key: 'openreport',
      icon: <PlusCircleOutlined />,
      
    },
    {
      label: t('ChartOption.ChartFormatting'),
      key: 'formatreport',
      icon: <SettingOutlined />,
    },
    {
      label: t('ChartOption.Download'),
      key: 'download',
      icon: <DownloadOutlined />,     
    },
    {
      label: t('ChartOption.Chart_Help'),
      key: 'help',
      icon: <QuestionCircleOutlined />,      
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  async function handleToggleAction(elementName) {
    let toggler = document.getElementsByClassName('caret');
    let nested = document.getElementsByClassName('nested');

    await _.forEach(toggler, function (objectVal, index) {
      if (objectVal.id === elementName) {
        objectVal.classList.toggle('caret-down');
      } else {
        objectVal.classList.remove('caret-down');
      }
    });

    await _.forEach(nested, function (objectVal, index) {
      if (objectVal.id === elementName) {
        objectVal.classList.toggle('active');
      } else {
        objectVal.classList.remove('active');
      }
    });
  }

  function handleOptionClick(event) {
    handleToggleAction(event.target.id);
  }

  async function saveDashboard() {
    setLoading(true);

    store.addNotification({
      title: t('SuccessMessage.done'),
      message: t('SuccessMessage.pleasewaitWhilewesave'),
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'zoomIn'],
      animationOut: ['animated', 'zoomOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
        showIcon: true,
      },
    });

    let chartSettings = {
      indexFirst: props.chartDataObject.index_object_first,
      reportFirst: props.chartDataObject.report_object_first,
      indexSecond: props.chartDataObject.index_object_second,
      reportSecond: props.chartDataObject.report_object_second,
      indexThird: props.chartDataObject.index_object_third,
      reportThird: props.chartDataObject.report_object_third,
      defaultChartSetting: props.chartSettingsObject.settingConfig_default,
      firstChartSetting: props.chartSettingsObject.settingConfig_first,
      secondChartSetting: props.chartSettingsObject.settingConfig_second,
      thirdChartSetting: props.chartSettingsObject.settingConfig_third,
      dashboardTitle: props.dashboardTitleObject.title,
      dashboardTitleColor: props.dashboardTitleObject.titleColor,
      dashboardSubTitle: props.dashboardTitleObject.subtitle,
      dashboardSubTitleColor: props.dashboardTitleObject.subTitleColor,
      dashboardFont: props.dashboardTitleObject.fontFamily,
    };

    axios
      .post(API_SERVER + `s3-save-new-dashboard-default-setting`, {
        userEmail: userEmail,
        chartSettings: chartSettings,
      })
      .then(function (response) {
        if (response.status === 200) {
        } else {
          store.addNotification({
            title: t('ErrorMessage.error'),
            message: t('ErrorMessage.error'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: t('ErrorMessage.error'),
          message: t('ErrorMessage.error'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .finally(function () {
        setLoading(false);
      });
  }

  const openHelpPopup = () => {
    setShowHelpPopup((showHelpPopup = true));
  };

  const closeHelpPopup = () => {
    setShowHelpPopup((showHelpPopup = false));
  };

  const openPrintPopup = () => {
    setShowPrintPopup((showPrintPopup = true));
  };

  const closePrintPopup = () => {
    setShowPrintPopup((showPrintPopup = false));
  };

  const onPrintModalCloseAfterApi = (event) => {
    setLoading(true);
    if (event === 'PDF') {
      let input = document.getElementsByClassName('chart-img')[0];

      html2canvas(input).then((canvas) => {
        let imgData = canvas.toDataURL('image/png');

        const pdf2 = new pdfConverter({
          orientation: 'landscape',
        });
        const imgProps = pdf2.getImageProperties(imgData);
        const pdfWidth = pdf2.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf2.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf2.save('chartHeader' + `.pdf`);
      });
    } else if (event === 'JPEG') {
      let input = document.getElementsByClassName('chart-img')[0];

      html2canvas(input).then((canvas) => {
        let canvasImage = canvas.toDataURL('image/png');

        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(xhr.response);
          a.download = 'chartHeader' + `.jpeg`;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          a.remove();
        };
        xhr.open('GET', canvasImage); // This is to download the canvas Image
        xhr.send();
      });
    } else {
    }
    setLoading(false);
  };

  const openReportPopup = () => {
    setShowReportPopup((showReportPopup = true));
  };

  const closeReportPopup = () => {
    setShowReportPopup((showReportPopup = false));
  };

  const onReportModalCloseAfterApi = (
    directoryDetails,
    fileDetails,
    loadedSettings,
    pos
  ) => {
    if (pos === 'First') {
      onFirstReportModalCloseAfterApi(
        directoryDetails,
        fileDetails,
        loadedSettings
      );
    } else if (pos === 'Second') {
      onSecondReportModalCloseAfterApi(
        directoryDetails,
        fileDetails,
        loadedSettings
      );
    } else {
      onThirdReportModalCloseAfterApi(
        directoryDetails,
        fileDetails,
        loadedSettings
      );
    }
  };

  const onFirstReportModalCloseAfterApi = (
    directoryDetails,
    fileDetails,
    loadedSettings
  ) => {
    if (directoryDetails != null) {
      setLoading(true);
      if (loadedSettings === undefined) {
        props.updateChartSettingObject({
          settingConfig_first: '',
          position_first: false,
        });
        readSelectedFile(directoryDetails, fileDetails, 'First');
      } else {
        props.updateChartSettingObject({
          settingConfig_first: loadedSettings,
          position_first: true,
        });
        readSelectedFileApplySettings(
          directoryDetails,
          fileDetails,
          loadedSettings,
          'First'
        );
      }
      props.updateChartDataObject({
        index_object_first: directoryDetails,
        report_object_first: fileDetails,
      });
    } else {
      setfGraphData((fgraphData = []));
    }
  };

  const onSecondReportModalCloseAfterApi = (
    directoryDetails,
    fileDetails,
    loadedSettings
  ) => {
    if (directoryDetails != null) {
      setLoading(true);
      if (loadedSettings === undefined) {
        props.updateChartSettingObject({
          settingConfig_second: '',
          position_second: false,
        });
        readSelectedFile(directoryDetails, fileDetails, 'Second');
      } else {
        props.updateChartSettingObject({
          settingConfig_second: loadedSettings,
          position_second: true,
        });
        readSelectedFileApplySettings(
          directoryDetails,
          fileDetails,
          loadedSettings,
          'Second'
        );
      }
      props.updateChartDataObject({
        index_object_second: directoryDetails,
        report_object_second: fileDetails,
      });
    } else {
      setdGraphData((dgraphData = []));
    }
  };

  const onThirdReportModalCloseAfterApi = (
    directoryDetails,
    fileDetails,
    loadedSettings
  ) => {
    if (directoryDetails != null) {
      setLoading(true);
      if (loadedSettings === undefined) {
        props.updateChartSettingObject({
          settingConfig_third: '',
          position_third: false,
        });
        readSelectedFile(directoryDetails, fileDetails, 'Third');
      } else {
        props.updateChartSettingObject({
          settingConfig_third: loadedSettings,
          position_third: true,
        });
        readSelectedFileApplySettings(
          directoryDetails,
          fileDetails,
          loadedSettings,
          'Third'
        );
      }
      props.updateChartDataObject({
        index_object_third: directoryDetails,
        report_object_third: fileDetails,
      });
    } else {
      settGraphData((tgraphData = []));
    }
  };

  async function getUserDetails() {
    let userInfo = await Auth.currentUserInfo();
    console.log("userInfo",userInfo)
    if (userInfo != null) {
      setUserEmail((userEmail = userInfo.attributes.email));
    }
    await getFileList();
    setLoading(false);
  }

  async function getFileList() {

    await axios
      .post(API_SERVER + `s3-file-read-complete-folder`, {
        userEmail: userEmail,
        sortOrder: 'asc',
        directoryName:''
      })
      .then(function (response) {
        console.log(response, '=test');
        if (response.status === 200) {

    axios
      .post(API_SERVER + `s3-zip-file-read-with-category-list`, {
        userEmail: userEmail,
        directoryName: '',
        sortOrder: 'asc',
        completeFiles: response.data.data,
        showErrorFileStatus: true
      })
      .then(function (response1) {
        if (response1.status === 200) {
          console.log("response1>>",response1.data.data)
          setReportPopUpData((reportPopUpData = response1.data.data));
        } else {
          store.addNotification({
            title: t('ErrorMessage.error'),
            message: t('ErrorMessage.error'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: t('ErrorMessage.error'),
          message: t('ErrorMessage.error'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
        checkPreSavedDashboard();
      });

    }
  })
  }

  async function checkPreSavedDashboard() {
    axios
      .post(API_SERVER + `s3-get-dashboard-config-file`, {
        userEmail: userEmail,
      })
      .then(function (response) {
        if (response.status === 200) {
          let dashboardSetting = response.data;

          props.updateDashboardTitleObject({
            title: dashboardSetting.dashboardTitle,
            titleColor: dashboardSetting.dashboardTitleColor,
            subTitleColor: dashboardSetting.dashboardSubTitleColor,
            fontFamily: dashboardSetting.dashboardFont,
            subtitle: dashboardSetting.dashboardSubTitle,
          });
          if (dashboardSetting.indexFirst != '') {
            if (dashboardSetting.firstChartSetting === '') {
              onFirstReportModalCloseAfterApi(
                dashboardSetting.indexFirst,
                dashboardSetting.reportFirst
              );
            } else {
              onFirstReportModalCloseAfterApi(
                dashboardSetting.indexFirst,
                dashboardSetting.reportFirst,
                dashboardSetting.firstChartSetting
              );
            }
          }

          if (dashboardSetting.indexSecond != '') {
            if (dashboardSetting.secondChartSetting === '') {
              onSecondReportModalCloseAfterApi(
                dashboardSetting.indexSecond,
                dashboardSetting.reportSecond
              );
            } else {
              onSecondReportModalCloseAfterApi(
                dashboardSetting.indexSecond,
                dashboardSetting.reportSecond,
                dashboardSetting.secondChartSetting
              );
            }
          }

          if (dashboardSetting.indexThird != '') {
            if (dashboardSetting.thirdChartSetting === '') {
              onThirdReportModalCloseAfterApi(
                dashboardSetting.indexThird,
                dashboardSetting.reportThird
              );
            } else {
              onThirdReportModalCloseAfterApi(
                dashboardSetting.indexThird,
                dashboardSetting.reportThird,
                dashboardSetting.thirdChartSetting
              );
            }
          }
        } else {
          store.addNotification({
            title: t('ErrorMessage.error'),
            message: t('ErrorMessage.error'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: t('ErrorMessage.error'),
          message: t('ErrorMessage.error'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {});
  }

  async function checkPreSavedSettings() {
    {
      /*store.addNotification({
              title: "Success",
              message: "Checking for pre saved settings",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "zoomIn"],
              animationOut: ["animated", "zoomOut"],
              dismiss: {
                duration: 3000,
                onScreen: true ,
                showIcon :true
              }
             });*/
    }
    console.log("userInfo",userEmail)
    axios
      .post(API_SERVER + `s3-get-user-chart-default-setting`, {
        userEmail: userEmail,
      })
      .then(function (response) {
        if (response.status === 200) {
          let chartSettings = response.data;

          props.updateChartSettingObject({
            settingConfig_default: chartSettings,
          });

          props.updateChartTypeObject({ chart_type: chartSettings.chart_type });

          props.updateBarColorObject({
            fillColor: {
              r: chartSettings.barFillColor.r,
              g: chartSettings.barFillColor.g,
              b: chartSettings.barFillColor.b,
              a: chartSettings.barFillColor.a,
            },
            strokeColor: {
              r: chartSettings.barStrokeColor.r,
              g: chartSettings.barStrokeColor.g,
              b: chartSettings.barStrokeColor.b,
              a: chartSettings.barStrokeColor.a,
            },
          });

          props.updateChartTextObject({
            fontFamily: chartSettings.fontFamily,
            titleColor: chartSettings.titleColor,
            subTitleColor: chartSettings.subTitleColor,
          });

          props.updateChartBaseObject({
            background_color: chartSettings.background_color,
          });

          props.updateChartLegendObject({
            fontColor: {
              r: chartSettings.legendFontColor.r,
              g: chartSettings.legendFontColor.g,
              b: chartSettings.legendFontColor.b,
              a: chartSettings.legendFontColor.a,
            },
          });

          props.updateLineColorObject({
            pointStyle: chartSettings.linePointStyle,
            fillColor: {
              r: chartSettings.lineFillColor.r,
              g: chartSettings.lineFillColor.g,
              b: chartSettings.lineFillColor.b,
              a: chartSettings.lineFillColor.a,
            },
            strokeColor: {
              r: chartSettings.lineStrokeColor.r,
              g: chartSettings.lineStrokeColor.g,
              b: chartSettings.lineStrokeColor.b,
              a: chartSettings.lineStrokeColor.a,
            },
            pointsColor: {
              r: chartSettings.linePointColor.r,
              g: chartSettings.linePointColor.g,
              b: chartSettings.linePointColor.b,
              a: chartSettings.linePointColor.a,
            },
            pointStrokeColor: {
              r: chartSettings.linePointStrokeColor.r,
              g: chartSettings.linePointStrokeColor.g,
              b: chartSettings.linePointStrokeColor.b,
              a: chartSettings.linePointStrokeColor.a,
            },
            checkFillColor: chartSettings.lineCheckFillColor,
            linePointRadius: chartSettings.linePointRadius,
          });
        }
      })
      .catch(function (error) {})
      .finally(function () {
        setLoading(false);
        setShow((show = false));
      });
  }

  async function readSelectedFileApplySettings(
    directoryDetails,
    fileDetails,
    chartSettings,
    pos
  ) {
    setLoading(true);

    axios
      .post(API_SERVER + `s3-zip-file-get-content`, {
        userEmail: userEmail,
        directoryName: directoryDetails.Key,
        fileName: fileDetails.key,
      })
      .then(function (response) {
        if (response.status === 200) {
          if (pos === 'First') {
            props.updateBarColorObject({
              fillColor_first: {
                r: chartSettings.barFillColor.r,
                g: chartSettings.barFillColor.g,
                b: chartSettings.barFillColor.b,
                a: chartSettings.barFillColor.a,
              },
              strokeColor_first: {
                r: chartSettings.barStrokeColor.r,
                g: chartSettings.barStrokeColor.g,
                b: chartSettings.barStrokeColor.b,
                a: chartSettings.barStrokeColor.a,
              },
            });

            props.updateChartTextObject({
              fontFamily_first: chartSettings.fontFamily,
              titleColor_first: chartSettings.titleColor,
              subTitleColor_first: chartSettings.subTitleColor,
              title_first: chartSettings.title,
              subtitle_first: chartSettings.subTitle,
            });

            props.updateChartBaseObject({
              background_color_first: chartSettings.background_color,
            });

            props.updateChartLegendObject({
              fontColor_first: {
                r: chartSettings.legendFontColor.r,
                g: chartSettings.legendFontColor.g,
                b: chartSettings.legendFontColor.b,
                a: chartSettings.legendFontColor.a,
              },
              display_first: chartSettings.legendDisplay,
              position_first: chartSettings.legendPosition,
            });

            props.updateLineColorObject({
              pointStyle_first: chartSettings.linePointStyle,
              fillColor_first: {
                r: chartSettings.lineFillColor.r,
                g: chartSettings.lineFillColor.g,
                b: chartSettings.lineFillColor.b,
                a: chartSettings.lineFillColor.a,
              },
              strokeColor_first: {
                r: chartSettings.lineStrokeColor.r,
                g: chartSettings.lineStrokeColor.g,
                b: chartSettings.lineStrokeColor.b,
                a: chartSettings.lineStrokeColor.a,
              },
              pointsColor_first: {
                r: chartSettings.linePointColor.r,
                g: chartSettings.linePointColor.g,
                b: chartSettings.linePointColor.b,
                a: chartSettings.linePointColor.a,
              },
              pointStrokeColor_first: {
                r: chartSettings.linePointStrokeColor.r,
                g: chartSettings.linePointStrokeColor.g,
                b: chartSettings.linePointStrokeColor.b,
                a: chartSettings.linePointStrokeColor.a,
              },
              checkFillColor_first: chartSettings.lineCheckFillColor,
              linePointRadius_first: chartSettings.linePointRadius,
            });

            getChartDetails(response.data, 'First');
          } else if (pos === 'Second') {
            props.updateChartTextObject({
              fontFamily_second: chartSettings.fontFamily,
              titleColor_second: chartSettings.titleColor,
              subTitleColor_second: chartSettings.subTitleColor,
              title_second: chartSettings.title,
              subtitle_second: chartSettings.subTitle,
            });

            props.updateChartBaseObject({
              background_color_second: chartSettings.background_color,
            });

            props.updateChartLegendObject({
              fontColor_second: {
                r: chartSettings.legendFontColor.r,
                g: chartSettings.legendFontColor.g,
                b: chartSettings.legendFontColor.b,
                a: chartSettings.legendFontColor.a,
              },
              display_second: chartSettings.legendDisplay,
              position_second: chartSettings.legendPosition,
            });

            props.updateBarColorObject({
              fillColor_second: {
                r: chartSettings.barFillColor.r,
                g: chartSettings.barFillColor.g,
                b: chartSettings.barFillColor.b,
                a: chartSettings.barFillColor.a,
              },
              strokeColor_second: {
                r: chartSettings.barStrokeColor.r,
                g: chartSettings.barStrokeColor.g,
                b: chartSettings.barStrokeColor.b,
                a: chartSettings.barStrokeColor.a,
              },
            });

            props.updateLineColorObject({
              pointStyle_second: chartSettings.linePointStyle,
              fillColor_second: {
                r: chartSettings.lineFillColor.r,
                g: chartSettings.lineFillColor.g,
                b: chartSettings.lineFillColor.b,
                a: chartSettings.lineFillColor.a,
              },
              strokeColor_second: {
                r: chartSettings.lineStrokeColor.r,
                g: chartSettings.lineStrokeColor.g,
                b: chartSettings.lineStrokeColor.b,
                a: chartSettings.lineStrokeColor.a,
              },
              pointsColor_second: {
                r: chartSettings.linePointColor.r,
                g: chartSettings.linePointColor.g,
                b: chartSettings.linePointColor.b,
                a: chartSettings.linePointColor.a,
              },
              pointStrokeColor_second: {
                r: chartSettings.linePointStrokeColor.r,
                g: chartSettings.linePointStrokeColor.g,
                b: chartSettings.linePointStrokeColor.b,
                a: chartSettings.linePointStrokeColor.a,
              },
              checkFillColor_second: chartSettings.lineCheckFillColor,
              linePointRadius_second: chartSettings.linePointRadius,
            });

            getChartDetails(response.data, 'Second');
          } else {
            props.updateBarColorObject({
              fillColor_third: {
                r: chartSettings.barFillColor.r,
                g: chartSettings.barFillColor.g,
                b: chartSettings.barFillColor.b,
                a: chartSettings.barFillColor.a,
              },
              strokeColor_third: {
                r: chartSettings.barStrokeColor.r,
                g: chartSettings.barStrokeColor.g,
                b: chartSettings.barStrokeColor.b,
                a: chartSettings.barStrokeColor.a,
              },
            });

            props.updateChartTextObject({
              fontFamily_third: chartSettings.fontFamily,
              titleColor_third: chartSettings.titleColor,
              subTitleColor_third: chartSettings.subTitleColor,
              title_third: chartSettings.title,
              subtitle_third: chartSettings.subTitle,
            });

            props.updateChartBaseObject({
              background_color_third: chartSettings.background_color,
            });

            props.updateChartLegendObject({
              fontColor_third: {
                r: chartSettings.legendFontColor.r,
                g: chartSettings.legendFontColor.g,
                b: chartSettings.legendFontColor.b,
                a: chartSettings.legendFontColor.a,
              },
              display_third: chartSettings.legendDisplay,
              position_third: chartSettings.legendPosition,
            });

            props.updateLineColorObject({
              pointStyle_third: chartSettings.linePointStyle,
              fillColor_third: {
                r: chartSettings.lineFillColor.r,
                g: chartSettings.lineFillColor.g,
                b: chartSettings.lineFillColor.b,
                a: chartSettings.lineFillColor.a,
              },
              strokeColor_third: {
                r: chartSettings.lineStrokeColor.r,
                g: chartSettings.lineStrokeColor.g,
                b: chartSettings.lineStrokeColor.b,
                a: chartSettings.lineStrokeColor.a,
              },
              pointsColor_third: {
                r: chartSettings.linePointColor.r,
                g: chartSettings.linePointColor.g,
                b: chartSettings.linePointColor.b,
                a: chartSettings.linePointColor.a,
              },
              pointStrokeColor_third: {
                r: chartSettings.linePointStrokeColor.r,
                g: chartSettings.linePointStrokeColor.g,
                b: chartSettings.linePointStrokeColor.b,
                a: chartSettings.linePointStrokeColor.a,
              },
              checkFillColor_third: chartSettings.lineCheckFillColor,
              linePointRadius_third: chartSettings.linePointRadius,
            });

            getChartDetails(response.data, 'Third');
          }
        } else {
          store.addNotification({
            title: t('ErrorMessage.error'),
            message: t('ErrorMessage.error'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: t('ErrorMessage.error'),
          message: t('ErrorMessage.error'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .finally(function () {
        setLoading(false);
      });
  }

  async function readSelectedFile(directoryDetails, fileDetails, pos) {
    axios
      .post(API_SERVER + `s3-zip-file-get-content`, {
        userEmail: userEmail,
        directoryName: directoryDetails.Key,
        fileName: fileDetails.value,
      })
      .then(function (response) {
        if (response.status === 200) {
          // setChartHeader(chartHeader=fileDetails.label);
          // checkPreSavedSettings();
          if (pos === 'First') {
            getChartDetails(response.data, 'First');
            setFirstChartHeader((firstChartHeader = fileDetails.label));
            props.updateChartTextObject({ title_first: fileDetails.label });
          } else if (pos === 'Second') {
            getChartDetails(response.data, 'Second');
            setSecondChartHeader((secondChartHeader = fileDetails.label));
            props.updateChartTextObject({ title_second: fileDetails.label });
          } else {
            getChartDetails(response.data, 'Third');
            setThirdChartHeader((thirdChartHeader = fileDetails.label));
            props.updateChartTextObject({ title_third: fileDetails.label });
          }
        } else {
          store.addNotification({
            title: t('ErrorMessage.error'),
            message: t('ErrorMessage.error'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: t('ErrorMessage.error'),
          message: t('ErrorMessage.error'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
       // checkPreSavedSettings();
      });
  }

  async function getChartDetails(data, pos) {
    console.log(data);
    if (data === null) {
      if (pos === 'First') {
        setfLabels((flabels = []));
        setfGraphData((fgraphData = []));
      } else if (pos === 'Second') {
        setdLabels((dlabels = []));
        setdGraphData((dgraphData = []));
      } else {
        settLabels((tlabels = []));
        settGraphData((tgraphData = []));
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      let label_array = [];
      let value_array = [];
      let color_array = [];
      let complete_array = [];

      if (data.length > 0) {
        await _.forEach(data, function (objectVal, index) {
          let keys = Object.keys(objectVal);

          let second_key = keys[1];
          let first_key = keys[0];
          color_array.push(
            '#' + Math.floor(Math.random() * 16777215).toString(16)
          );

          if (objectVal[first_key] === null || objectVal[second_key] === null) {
          } else {
            if (typeof objectVal[keys[1]] === 'string') {
              value_array.push(objectVal[keys[0]]);
              label_array.push(objectVal[keys[1]]);

              if (pos === 'First') {
                setXaxisfLabel((xAxisfLabel = keys[1]));
                setYaxisfLabel((yAxisfLabel = keys[0]));
              } else if (pos === 'Second') {
                setXaxisdLabel((xAxisdLabel = keys[1]));
                setYaxisdLabel((yAxisdLabel = keys[0]));
              } else {
                setXaxistLabel((xAxistLabel = keys[1]));
                setYaxistLabel((yAxistLabel = keys[0]));
              }
              complete_array.push({
                value: objectVal[keys[0]],
                name: objectVal[keys[1]],
              });
            } else {
              value_array.push(objectVal[keys[1]]);
              label_array.push(objectVal[keys[0]]);

              if (pos === 'First') {
                setXaxisfLabel((xAxisfLabel = keys[0]));
                setYaxisfLabel((yAxisfLabel = keys[1]));
              } else if (pos === 'Second') {
                setXaxisdLabel((xAxisdLabel = keys[0]));
                setYaxisdLabel((yAxisdLabel = keys[1]));
              } else {
                setXaxistLabel((xAxistLabel = keys[0]));
                setYaxistLabel((yAxistLabel = keys[1]));
              }

              complete_array.push({
                value: objectVal[keys[1]],
                name: objectVal[keys[0]],
              });
            }

            if (
              typeof objectVal[keys[1]] === 'number' &&
              typeof objectVal[keys[0]] === 'number'
            ) {
              complete_array.push({ x: objectVal[0], y: objectVal[1] });
            }
          }
        });
      }

      console.log(pos, complete_array, label_array, value_array);
      label_array = data.label_array;
      value_array = data.value_array;
      xAxisdLabel = data.xaxis_label;
      yAxisdLabel = data.yaxis_label;

      if (pos === 'First') {
        setfCompleteArray((fcompleteArray = complete_array));
        setfLabels((flabels = label_array));
        setfGraphData((fgraphData = value_array));
        setfColors((fcolors = color_array));

        props.updateChartDataObject({
          chart_labels_first: label_array,
          chart_data_first: value_array,
          chart_colors_first: color_array,
          chart_array_first: fcompleteArray,
          chart_xaxis_label_first: xAxisfLabel,
          chart_yaxis_label_first: yAxisfLabel,
        });
      } else if (pos === 'Second') {
        setdCompleteArray((dcompleteArray = complete_array));
        setdLabels((dlabels = label_array));
        setdGraphData((dgraphData = value_array));
        setdColors((dcolors = color_array));

        props.updateChartDataObject({
          chart_labels_second: label_array,
          chart_data_second: value_array,
          chart_colors_second: color_array,
          chart_array_second: dcompleteArray,
          chart_xaxis_label_second: xAxisdLabel,
          chart_yaxis_label_second: yAxisdLabel,
        });
      } else {
        settCompleteArray((tcompleteArray = complete_array));
        settLabels((tlabels = label_array));
        settGraphData((tgraphData = value_array));
        settColors((tcolors = color_array));

        props.updateChartDataObject({
          chart_labels_third: label_array,
          chart_data_third: value_array,
          chart_colors_third: color_array,
          chart_array_third: tcompleteArray,
          chart_xaxis_label_third: xAxistLabel,
          chart_yaxis_label_third: yAxistLabel,
        });
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }

  useEffect(() => {
    checkUserSession();
    setLoading(true);
    getUserDetails();
  }, []);

  return (
    <React.Fragment>
      <PrintModal
        showPrintPopup={showPrintPopup}
        closePrintPopup={closePrintPopup}
        onPrintModalCloseAfterApi={onPrintModalCloseAfterApi}
        printPopUpData={printPopUpData}
      />
      <ReportModal
        showReportPopup={showReportPopup}
        closeReportPopup={closeReportPopup}
        onReportModalCloseAfterApi={onReportModalCloseAfterApi}
        reportPopUpData={reportPopUpData}
        userEmail={userEmail}
      />
      <HelpModal
        showHelpPopup={showHelpPopup}
        closeHelpPopup={closeHelpPopup}
      />
      <div id='wrapper' className='menu'>
        <div id='page-content-wrapper-tab'>
          <div
            className='page-content inset-tab'
            data-spy='scroll'
            data-target='#spy'
          >
            <div className='container-full'>
              <div className='boxWrapper'>
                <div className='page-content-row'>
                  <div className='row'>
                    <div
                      className={
                        !show ? 'col-md-12 rightspac' : 'col-md-9 rightspac'
                      }
                    >
                     {/* <div
                        className='lftHandCursor'
                        data-tip
                        data-for='ReportTip'
                        onClick={(e) => openReportPopup()}
                      >
                        <img src={plusSignBlue} alt="plusSignBlue" />
                         <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='60'
                          height='63'
                          viewBox='0 0 514.186 577.554'
                        >
                          <g
                            id='cursor'
                            transform='matrix(0.259, 0.966, -0.966, 0.259, 457.744, -22.176)'
                          >
                            <g id='Group_2' data-name='Group 2'>
                              <g id='Group_1' data-name='Group 1'>
                                <path
                                  id='Path_1'
                                  data-name='Path 1'
                                  d='M461.63,251.914l-15.038-34.207c-.89-2.026-1.879-4.035-2.938-5.968A52.313,52.313,0,0,0,376.889,189l-5.8,2.52-7.4-16.845,0-.007-5.456-12.41A43.12,43.12,0,0,0,301.236,140.2l-19.228,8.33A35.988,35.988,0,0,0,269.094,137c-10.521-5.445-23.178-4.987-37.621,1.363l-13.759,6.048L199.9,103.9c-7.25-16.493-20.706-27.81-36.916-31.05-15.239-3.046-30.755,1.265-41.51,11.53a51.818,51.818,0,0,0-11.822,58.9L177.122,297.71a79.493,79.493,0,0,0-17.643-1.519c-15.691.387-29.8,5.377-39.247,13.79a10.007,10.007,0,0,0-1.737,1.428c-9.494,9.777-14.174,21.6-13.537,34.2.8,15.743,9.79,31.268,26.006,44.9.082.069.165.137.25.2L269.9,499.858a10,10,0,0,0,10.209,1.3l198.672-87.337a10,10,0,0,0,5.956-8.531l1.081-17.312A294,294,0,0,0,461.63,251.914Zm4.223,134.814-.7,11.231-187.6,82.472L143.715,375.1c-11.612-9.8-18.28-20.623-18.78-30.5-.324-6.4,1.87-12.364,6.526-17.748a10.133,10.133,0,0,0,.964-.873c11.971-12.329,41.824-13.181,57.414-1.748l34.447,33.084a10,10,0,0,0,13.855-14.425L204.625,310.7,127.977,135.246c-6.717-15.28-.627-28.821,7.313-36.4,5.953-5.682,15.066-8.129,23.782-6.386,9.87,1.973,17.87,8.893,22.527,19.486l58.06,132.072a10,10,0,0,0,18.309-8.049l-32.2-73.249,13.76-6.049c8.636-3.795,15.493-4.438,20.379-1.909,3.458,1.79,6.373,5.338,8.663,10.546l34.24,77.888a10,10,0,0,0,18.309-8.049l-30.194-68.685,18.32-7.937c.039-.017.077-.033.115-.051a23.121,23.121,0,0,1,30.567,11.832l40.687,92.565a10,10,0,0,0,18.309-8.049l-19.779-44.992,5.722-2.488a32.129,32.129,0,0,1,41.244,13.994c.783,1.431,1.514,2.916,2.173,4.416l15.039,34.208A273.945,273.945,0,0,1,465.853,386.728Z'
                                />
                                <path
                                  id='Path_2'
                                  data-name='Path 2'
                                  d='M426.561,384.075,416.7,388.4a10,10,0,1,0,8.033,18.315l9.865-4.327a10,10,0,0,0-8.033-18.315Z'
                                />
                                <path
                                  id='Path_3'
                                  data-name='Path 3'
                                  d='M386.77,401.751,281.417,447.959a10,10,0,1,0,8.033,18.316L394.8,420.066a10,10,0,0,0-8.033-18.315Z'
                                />
                                <path
                                  id='Path_4'
                                  data-name='Path 4'
                                  d='M127.017,45.2A10,10,0,1,0,146.6,41.153l-6.85-33.174a10,10,0,1,0-19.586,4.044Z'
                                />
                                <path
                                  id='Path_5'
                                  data-name='Path 5'
                                  d='M41.035,46.913A10,10,0,0,0,37.8,33.146l-6.907-4.277a10,10,0,1,0-10.531,17l6.907,4.277a10,10,0,0,0,13.767-3.236Z'
                                />
                                <path
                                  id='Path_6'
                                  data-name='Path 6'
                                  d='M60.454,72.245,81.786,86.276a10,10,0,1,0,10.991-16.71L71.444,55.535a10,10,0,0,0-10.99,16.71Z'
                                />
                                <path
                                  id='Path_7'
                                  data-name='Path 7'
                                  d='M86.622,135.491a10,10,0,0,0-11.816-7.771l-33.174,6.85a10,10,0,0,0,2.012,19.795,10.109,10.109,0,0,0,2.033-.208l33.174-6.85A10,10,0,0,0,86.622,135.491Z'
                                />
                                <path
                                  id='Path_8'
                                  data-name='Path 8'
                                  d='M111.775,186.982a10,10,0,0,0-13.85,2.86l-18.614,28.3a10,10,0,1,0,16.71,10.99l18.614-28.3A10,10,0,0,0,111.775,186.982Z'
                                />
                                <path
                                  id='Path_9'
                                  data-name='Path 9'
                                  d='M221.355,105.18a10,10,0,0,0,9.783,7.979,10.116,10.116,0,0,0,2.033-.208l22.116-4.567A10,10,0,1,0,251.242,88.8l-22.116,4.567A10,10,0,0,0,221.355,105.18Z'
                                />
                                <path
                                  id='Path_10'
                                  data-name='Path 10'
                                  d='M293.34,100.315a10.109,10.109,0,0,0,2.033-.208l6.912-1.427a10,10,0,1,0-4.045-19.587l-6.912,1.427a10,10,0,0,0,2.012,19.795Z'
                                />
                                <path
                                  id='Path_11'
                                  data-name='Path 11'
                                  d='M190.907,61.982a10,10,0,0,0,13.85-2.86l18.614-28.3a10,10,0,0,0-16.71-10.99l-18.614,28.3A10,10,0,0,0,190.907,61.982Z'
                                />
                              </g>
                            </g>
                          </g>
                        </svg> 
                      </div>*/}
                      <div
                        className='hide_show_chart'
                        data-tip
                        data-for='formatReportTip'
                        onClick={toggleGraph}
                      >
                        {show ? (
                          <p>
                            <i className='fa fa-times-circle'></i>
                          </p>
                        ) : ""}
                      </div>
                      <div className='left-side-box'>
                        <div className='chart-img'>
                          <div className='card-header'>
                          <div class="text-align-left">
                          {/*chartSelectedDataLabel.fileDetails.label*/}
                          </div>
                          <div class="text-align-right">
                              <Dropdown menu={menuProps}>
                                <Button>
                                  <Space>
                                    Chart Options
                                    <DownOutlined />
                                  </Space>
                                </Button>
                              </Dropdown>
                            </div>
                          </div>
                          
                          <div className='upl-file-row'>
                            <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                              <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                                <h3
                                // style={{
                                //   color:
                                //     props.dashboardTitleObject.titleColor,
                                //   fontFamily:
                                //     props.dashboardTitleObject.fontFamily,
                                // }}
                                >
                                  {props.dashboardTitleObject.title}
                                </h3>
                                <h4
                                  style={{
                                    color:
                                      props.dashboardTitleObject.subTitleColor,
                                    fontFamily:
                                      props.dashboardTitleObject.fontFamily,
                                  }}
                                >
                                  {props.dashboardTitleObject.subtitle}
                                </h4>
                                {props.chartSettingsObject.position_first ? (
                                  <div
                                    className='firstChart'
                                    // style={{
                                    //   backgroundColor:
                                    //     props.chartBaseObject
                                    //       .background_color_first,
                                    // }}
                                  >
                                    <ChartIndex chartIndex='first' />
                                  </div>
                                ) : props.chartDataObject.chart_data_first
                                    .length > 0 ? (
                                  <Draggable>
                                    <div
                                      className='firstChart'
                                      style={{
                                        backgroundColor:
                                          props.chartBaseObject
                                            .background_color,
                                      }}
                                    >
                                      <FBarChart />
                                    </div>
                                  </Draggable>
                                ) : null}
                              </div>
                              <div
                                className='col-md-6 col-lg-6 pl-0 custom-filter'
                                // style={{
                                //   backgroundColor:
                                //     props.chartBaseObject
                                //       .background_color_second,
                                // }}
                              >
                                {props.chartSettingsObject.position_second ? (
                                  <div
                                    className='secondChart'
                                    // style={{
                                    //   backgroundColor:
                                    //     props.chartBaseObject
                                    //       .background_color_second,
                                    // }}
                                  >
                                    <ChartIndex chartIndex='second' />
                                  </div>
                                ) : props.chartDataObject.chart_data_second
                                    .length > 0 ? (
                                  <Draggable>
                                    <div
                                      className='secondChart'
                                      style={{
                                        backgroundColor:
                                          props.chartBaseObject
                                            .background_color,
                                      }}
                                    >
                                      <SBarChart />
                                    </div>
                                  </Draggable>
                                ) : null}
                              </div>
                              <div
                                className='col-md-6 col-lg-6 pl-0 custom-filter'
                                // style={{
                                //   backgroundColor:
                                //     props.chartBaseObject
                                //       .background_color_third,
                                // }}
                              >
                                {props.chartSettingsObject.position_third ? (
                                  <Draggable>
                                    <div
                                      className='firstChart'
                                      style={{
                                        backgroundColor:
                                          props.chartBaseObject
                                            .background_color_third,
                                      }}
                                    >
                                      <ChartIndex chartIndex='third' />
                                    </div>
                                  </Draggable>
                                ) : props.chartDataObject.chart_data_third
                                    .length > 0 ? (
                                  <Draggable>
                                    <div
                                      className='thirdChart'
                                      style={{
                                        backgroundColor:
                                          props.chartBaseObject
                                            .background_color,
                                      }}
                                    >
                                      <TBarChart />
                                    </div>
                                  </Draggable>
                                ) : null}
                              </div>
                              {!(
                                props.chartDataObject.chart_data_first.length >
                                  0 ||
                                props.chartDataObject.chart_data_second.length >
                                  0 ||
                                props.chartDataObject.chart_data_third.length >
                                  0
                              ) ? (
                                <React.Fragment>
                                  <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                                    <div className='firstChart'>
                                      <p>
                                        {' '}
                                        {t('Text.pleaseSelectFirstReport')}
                                      </p>
                                    </div>
                                  </div>
                                  <div className='col-md-6 col-lg-6 pl-0 custom-filter'>
                                    <div className='secondChart'>
                                      <p>
                                        {' '}
                                        {t('Text.pleaseSelectSecondReport')}
                                      </p>
                                    </div>
                                  </div>
                                  <div className='col-md-6 col-lg-6 pl-0 custom-filter'>
                                    <div className='thirdChart'>
                                      <p>
                                        {' '}
                                        {t('Text.pleaseSelectThirdReport')}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                     {/* <div
                        className='leftHelpBottom'
                        data-tip
                        data-for='showHelpTip'
                        onClick={(e) => openHelpPopup()}
                      >
                        <svg
                          height='112pt'
                          viewBox='0 0 512 512'
                          width='112pt'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='m277.332031 384c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0' />
                          <path d='m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0' />
                          <path d='m256 314.667969c-8.832031 0-16-7.167969-16-16v-21.546875c0-20.308594 12.886719-38.507813 32.042969-45.269532 25.492187-8.980468 42.625-36.140624 42.625-55.851562 0-32.363281-26.304688-58.667969-58.667969-58.667969s-58.667969 26.304688-58.667969 58.667969c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16c0-49.984375 40.664063-90.667969 90.667969-90.667969s90.667969 40.683594 90.667969 90.667969c0 35.585938-28.097657 73.367188-63.980469 86.039062-6.398438 2.238282-10.6875 8.316407-10.6875 15.101563v21.527344c0 8.832031-7.167969 16-16 16zm0 0' />
                        </svg>
                      </div> */}

                      {/*<div className='save-as-row saveasrht'>
                        <div className='row col-sm-12 col-md-10 col-lg-8'>
                          <div className='col-md-4 col-lg-3 saveasrht'>
                            <button
                              type='button'
                              data-tip
                              data-for='savechartTip'
                              className='btn download-btn'
                              onClick={(e) => openPrintPopup()}
                            >
                              <svg
                                id='bold'
                                className='downloadicon'
                                enableBackground='new 0 0 24 24'
                                viewBox='0 0 24 24'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path d='m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z' />
                                <path d='m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z' />
                                <path d='m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z' />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>*/}
                    </div>
                    <div className='col-md-3  leftspac'>
                      {show ? (
                        <div className='right-side-box sidebar'>
                          <ul id='myUL' className='right-sidebar'>
                            <li>
                              <span
                                className='caret'
                                id='text'
                                onClick={handleOptionClick}
                              >
                                {t('Text.text')}
                              </span>
                              <TextBase />
                            </li>
                          </ul>
                          <div className='saveDel'>
                            <ReactTooltip
                              id='savechartsettingTip'
                              disable={props.toolTipObject.toolTip}
                              place='top'
                              delayHide={100}
                              effect='solid'
                            >
                              {t('Tooltip.saveDashboard')}
                            </ReactTooltip>

                            {props.chartDataObject.chart_data_first.length >
                              0 ||
                            props.chartDataObject.chart_data_second.length >
                              0 ||
                            props.chartDataObject.chart_data_third.length >
                              0 ? (
                              <span
                                data-tip
                                data-for='savechartsettingTip'
                                onClick={saveDashboard}
                              >
                                <img src={saveSVG} />
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {Loading && <Loader />}
        <ReactTooltip
          id='ReportTip'
          place='top'
          disable={props.toolTipObject.toolTip}
          delayHide={100}
          effect='solid'
        >
          {t('Tooltip.selectReport')}
        </ReactTooltip>

        <ReactTooltip
          id='saveDashboardTip'
          place='top'
          disable={props.toolTipObject.toolTip}
          delayHide={100}
          effect='solid'
        >
          {t('Tooltip.download')}
        </ReactTooltip>

        <ReactTooltip
          id='showHelpTip'
          place='top'
          disable={props.toolTipObject.toolTip}
          delayHide={100}
          effect='solid'
        >
          {t('Tooltip.saveDashboard')}
        </ReactTooltip>

        <ReactTooltip
          id='savechartTip'
          place='top'
          disable={props.toolTipObject.toolTip}
          delayHide={100}
          effect='solid'
        >
          {t('Tooltip.download')}
        </ReactTooltip>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const chartTypeObject = reducerObj.dashchartTypeReducers.chartType;
  const chartDataObject = reducerObj.dashchartDataReducers.chartData;
  const chartBaseObject = reducerObj.dashchartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.dashbarColorReducers.barColor;
  const chartTextObject = reducerObj.dashchartTextReducers.textData;
  const chartLegendObject = reducerObj.dashchartLegendReducers.legendData;
  const lineColorObject = reducerObj.dashlineColorReducers.LineColor;
  const chartSettingsObject =
    reducerObj.DashchartSettingsReducers.chartSettingsObject;
  const dashboardTitleObject = reducerObj.DashTitleSettingsReducers.titleData;
  const languageObject = reducerObj.translationReducers.langObject;
  const toolTipObject = reducerObj.toolTipReducers.toolTipObject;

  return {
    languageObject,
    toolTipObject,
    chartDataObject,
    chartBaseObject,
    barColorObject,
    chartTextObject,
    chartLegendObject,
    lineColorObject,
    chartSettingsObject,
    dashboardTitleObject,
    chartTypeObject,
  };
};

export default compose(
  connect(mapStateToProps, {
    updateChartTypeObject,
    resetChartTypeObject,
    updateChartDataObject,
    resetChartDataObject,
    updateChartTextObject,
    resetChartTextObject,
    updateChartLegendObject,
    resetChartLegendObject,
    updateLineColorObject,
    resetLineColorObject,
    updateBarColorObject,
    resetBarColorObject,
    updateChartBaseObject,
    resetChartBaseObject,
    updateChartSettingObject,
    resetChartSettingObject,
    updateDashboardTitleObject,
    resetDashboardTitleObject,
  })
)(Dashboard);
