import React, { useState } from 'react';
import { DownOutlined, UserOutlined, CalculatorOutlined, SubnodeOutlined, AccountBookOutlined, LogoutOutlined, DollarOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { history } from '../history/history';
import user_profile from "../../../assets/images/user_profile.png";
import ConfirmationAlertModal from "./ConfirmationAlertModal";
import { Auth } from "aws-amplify";
const App = () => {
  const [open, setOpen] = useState(false);
  let [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const handleMenuClick = (e) => {
    
    if(e.key=="editprofile" || e.key==="managecredits" || e.key==="subcription" || e.key==="payments" || e.key==="invoice"){       
        history.push(`/profile/${e.key}`);       
       }
       else if(e.key=="logout"){
         openConfirmPopup()
       }
       else if(e.key=="home"){
        history.push('/home');
       }
       else if(e.key=="pricing"){
        history.push('/pricing');
       }
  };

  const openConfirmPopup = () => {
    setShowConfirmPopup(showConfirmPopup = true);
  };

  const closeConfirmPopup = () => {
    setShowConfirmPopup(showConfirmPopup = false);
  };
  const onConfirmModalCloseAfterApi = (response) => {
    if (response === "true") {
      localStorage.removeItem("loginStatus");
      logOut();
    } else {
      setShowConfirmPopup(showConfirmPopup = false);
    }
  };
  async function logOut() {
    await Auth.signOut();
    localStorage.setItem("selectedItem", '');
    window.location.reload();
  };
//   const handleOpenChange = (nextOpen, info) => {
//     if (info.source === 'trigger' || nextOpen) {
//       setOpen(nextOpen);
//     }
//   };
const items = [
    // { label: 'Home', key: 'home', icon: <UserOutlined /> }, // remember to pass the key prop
    { label: 'Edit Profile', key: 'editprofile', icon: <UserOutlined /> },
    { label: 'Purchase Token', key: 'pricing', icon: <DollarOutlined /> },
    { label: 'Manage Credits', key: 'managecredits', icon: <CalculatorOutlined /> },
    // { label: 'Subcription', key: 'subcription', icon: <SubnodeOutlined /> },
    // { label: 'Payments', key: 'payments', icon: <AccountBookOutlined /> },
    { label: 'Invoice', key: 'invoice', icon: <AccountBookOutlined /> },
    { label: 'Logout', key: 'logout', icon: <LogoutOutlined /> },
  ];
  return (
    <>
    <ConfirmationAlertModal
        showConfirmPopup={showConfirmPopup}
        closeConfirmPopup={closeConfirmPopup}
        onConfirmModalCloseAfterApi={onConfirmModalCloseAfterApi}
        confirmPopupMessage={"Are you sure you want to log out?"}
      />
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
      }}
    //   onOpenChange={handleOpenChange}
    //   open={open}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
        <img className="prof" src={user_profile} />
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
    </>
  );
};
export default App;
