import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';

import { Auth } from 'aws-amplify';

import axios from 'axios';

import Header from '../common/Header/innerHeader';
//import items from '../common/Header/menuItems';
import MainMenu from "../common/Header/mainMenu";

import { Loader } from '../common/Loader/loader';

import { history } from '../common/history/history';

import { store } from 'react-notifications-component';

import { API_SERVER } from '../constant';

import ReportModal from './ReportModal';

import { updateIndexObject } from '../../actions/IndexAction';
import { updateReportObject } from '../../actions/ReportAction';
import { updateTabObject, resetTabObject } from '../../actions/TabAction';
import {
  updateLanguageObject,
  resetLanguageObject,
} from '../../actions/TranslationAction';
import {
  updateEChartTypeObject,
  resetEChartTypeObject,
} from '../../actions/EChartAction/EChartTypeAction';
import {
  updateEChartDataObject,
  resetEChartDataObject,
} from '../../actions/EChartAction/EChartDataAction';
import {
  updateEChartBaseObject,
  resetEChartBaseObject,
} from '../../actions/EChartAction/EChartBaseAction';
import {
  updateEChartTextObject,
  resetEChartTextObject,
} from '../../actions/EChartAction/EChartTextAction';
import {
  updateEChartEventObject,
  resetEChartEventObject,
} from '../../actions/EChartAction/EChartEventAction';
import {
  updateEChartLegendObject,
  resetEChartLegendObject,
} from '../../actions/EChartAction/EChartLegendAction';
import {
  updateEBarColorObject,
  resetEBarColorObject,
} from '../../actions/EChartAction/EBarColorAction';
import {
  updateELineColorObject,
  resetELineColorObject,
} from '../../actions/EChartAction/ELineColorAction';

import {
  updateEChartSettingObject,
  resetEChartSettingObject,
} from '../../actions/EChartAction/EChartSettingsAction';

import './homeWithTab.css';

import Home from '../HomeTab/Home';
import Dashboard from '../DashboardTab/Dashboard';
import UploadList from '../UploadListTab/UploadList';
import ZipList from '../ZipListTabLambda/ZipList';
import EChart from '../EChartTab/EChart';
import Pivot from '../PivotTab/Pivot';
import PrestoAnalytics from '../PrestoAnalyticsTab/prestoAnalytics';
import About from '../AboutTab/About';

import Footer from '../common/Footer/footer';
import MainPage from '../PresentationTwo/MainPage';
import Profile from '../Profile/MainPage';

function HomeWithTab(props) {
  const [selectedItem, setSelectedItem] = useState('1');
  // if(props.selectedItem) {
  //   console.log("inside props.selectedItem condition")
  //   const {selectedItem, setSelectedItem} = props;
  // }
 
  
  const { t, i18n } = useTranslation('common');
  let [Loading, setLoading] = useState(false);
  let [expand, setExpanded] = useState(false);
  let [userEmail, setUserEmail] = useState(null);
  let [tabIndex, setTabIndex] = useState(0);

  let [showReportPopup, setShowReportPopup] = useState(false);
  let [reportPopUpData, setReportPopUpData] = useState([]);
  //const [selectedItem, setSelectedItem] = useState('1');
  console.log('Inside HomeWithTab setSelectedItem', selectedItem, localStorage.getItem("selectedItem"));
  
  async function resetChartSettings() {
    props.resetEChartDataObject();
  }

  function tabChange(index, lastIndex) {
    if (lastIndex == 1) {
      let chartSettings = {
        canvas_width: props.chartBaseObject.canvasWidth,
        canvas_height: props.chartBaseObject.canvasHeight,
        chart_type: props.chartObject.chart_type,
        background_color: props.chartBaseObject.background_color,
        barFillColor: props.barColorObject.fillColor,
        barStrokeColor: props.barColorObject.strokeColor,
        titleColor: props.chartTextObject.titleColor,
        subTitleColor: props.chartTextObject.subTitleColor,
        fontFamily: props.chartTextObject.fontFamily,
        legendDisplay: props.chartLegendObject.display,
        legendPosition: props.chartLegendObject.position,
        legendLeftPosition: props.chartLegendObject.leftPosition,
        legendTopPosition: props.chartLegendObject.topPosition,
        legendOrientation: props.chartLegendObject.orientation,
        legendFontColor: props.chartLegendObject.fontColor,
        lineFillColor: props.lineColorObject.fillColor,
        lineStrokeColor: props.lineColorObject.strokeColor,
        linePointStrokeColor: props.lineColorObject.pointStrokeColor,
        linePointColor: props.lineColorObject.pointsColor,
        lineCheckFillColor: props.lineColorObject.checkFillColor,
        linePointRadius: props.lineColorObject.pointRadius,
        linePointStyle: props.lineColorObject.pointStyle,
      };

      let chartSelectedData = JSON.parse(
        localStorage.getItem('chartSelectedData')
      );
      if (chartSelectedData === null) {
      } else {
        let directoryDetails = chartSelectedData.directoryDetails;
        let fileDetails = chartSelectedData.fileDetails;
        let storageData = {
          directoryDetails: directoryDetails,
          fileDetails: fileDetails,
          tempSettings: chartSettings,
        };
        localStorage.setItem('chartSelectedData', JSON.stringify(storageData));
      }
    }
    props.updateTabObject({ tabIndex: index });
  }

  async function getUserDetails() {
    let userInfo = await Auth.currentUserInfo();
    if (userInfo != null) {
      setUserEmail((userEmail = userInfo.attributes.email));
    }
    getUploadedFileList();
  }

  async function getUploadedFileList() {
    axios
      .post(API_SERVER + `s3-zip-file-read`, {
        userEmail: userEmail,
        directoryName: '',
        sortOrder: 'asc',
      })
      .then(function (response) {
        if (response.status === 200) {
          setReportPopUpData((reportPopUpData = response.data.data));
          setShowReportPopup((showReportPopup = true));
        } else {
          store.addNotification({
            title: t('ErrorMessage.error'),
            message: t('ErrorMessage.error'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: t('ErrorMessage.error'),
          message: t('ErrorMessage.error'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
        setLoading(false);
      });
  }

  const closeReportPopup = () => {
    setShowReportPopup((showReportPopup = false));
  };

  const onReportModalCloseAfterApi = (
    directoryDetails,
    fileDetails,
    loadedSettings
  ) => {
    console.log(directoryDetails);
    console.log(fileDetails);
    console.log(loadedSettings);
    //let storageData = {'directoryDetails':directoryDetails,'fileDetails':fileDetails,'loadedSettings':loadedSettings};
    //localStorage.setItem('defaultSelectedReport', JSON.stringify(storageData));
  };

  async function onLoad() {
    try {
      let result = await Auth.currentSession();
    } catch (e) {
      history.push('/');
    }
  }

  useEffect(() => {    
    onLoad();
    //setLoading(true);
    //getUserDetails();
  }, [selectedItem]);

  useEffect(() => {
    setTabIndex(props.tabObject.tabIndex);
  }, [props.tabObject.tabIndex]);

  useEffect(() => {
    i18n.changeLanguage(props.languageObject.currentLanguage);
  }, [props.languageObject.currentLanguage]);

  let content;
  switch (localStorage.getItem("selectedItem")) {
    case '1':
      content = <Home selectedItem={selectedItem} setSelectedItem={setSelectedItem} />;
      break;
    case '2':
      content = <UploadList />;
      break;
    case '3':
      content = <EChart />;
      break;
    case '4':
      content = <Dashboard />;
      break;
    case '5':
      content = <ZipList />;
      break;
    case '6':
      content = <Pivot />;
      break;
    case '7':
      content = <MainPage />;
      break;
    case '8':
      content = <About />;
      break;
    case '9':
      content = <Profile />;
      break;
    default:
      content = <Home />;
      break;

  }

  return (
    <React.Fragment>
      <ReportModal
        showReportPopup={showReportPopup}
        closeReportPopup={closeReportPopup}
        onReportModalCloseAfterApi={onReportModalCloseAfterApi}
        reportPopUpData={reportPopUpData}
        userEmail={userEmail}
      />
      <Header 
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      
      />
      <div className='mainpage_navTab'>
        <div className='navTabList'>
          <div>
          {/* <MainMenu 
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}                 
                /> */}
          {content}
            {/* <Tabs
              selectedIndex={tabIndex}
              onSelect={(index, lastIndex) => tabChange(index, lastIndex)}
            >
              <TabList className='navbarpanel'>
                <Tab>{t('Header.home')}</Tab>
                <Tab>{t('Header.fileupload')}</Tab>
                <Tab>{t('Header.chartIt')}</Tab>
                <Tab>{t('Header.dashboard')}</Tab>
                <Tab>{t('Header.collection')}</Tab>
                <Tab>{t('Header.pivot')}</Tab>                
                <Tab>{t('Header.presentation')}</Tab>
                <Tab>{t('Header.about')}</Tab>
              </TabList>
              <TabPanel>
                <Home />
              </TabPanel>
              <TabPanel>
                <UploadList />
              </TabPanel>
              <TabPanel>
                <EChart />
              </TabPanel>
              <TabPanel>
                <Dashboard />
              </TabPanel>
              <TabPanel>
                <ZipList />
              </TabPanel>
              <TabPanel>
                <Pivot />
              </TabPanel>
              <TabPanel>
                <MainPage />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
            </Tabs> */}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  const chartDataObject = reducerObj.eChartDataReducers.chartData;
  const chartBaseObject = reducerObj.eChartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.eBarColorReducers.barColor;
  const chartTextObject = reducerObj.eChartTextReducers.textData;
  const chartEventObject = reducerObj.eChartEventReducers.eventList;
  const chartLegendObject = reducerObj.eChartLegendReducers.legendData;
  const lineColorObject = reducerObj.eLineColorReducers.LineColor;
  const chartSettingsObject =
    reducerObj.eChartSettingsReducers.chartSettingsObject;
  const indexObject = reducerObj.indexReducers.indexObject;
  const reportObject = reducerObj.reportReducers.reportObject;
  const tabObject = reducerObj.tabReducers.tabObject;
  const languageObject = reducerObj.translationReducers.langObject;
  return {
    languageObject,
    tabObject,
    chartObject,
    chartDataObject,
    chartBaseObject,
    barColorObject,
    chartTextObject,
    chartEventObject,
    chartLegendObject,
    lineColorObject,
    chartSettingsObject,
    indexObject,
    reportObject,
  };
};

export default compose(
  connect(mapStateToProps, {
    updateEChartTypeObject,
    resetEChartTypeObject,
    updateEChartDataObject,
    resetEChartDataObject,
    updateEChartBaseObject,
    resetEChartBaseObject,
    updateEChartTextObject,
    resetEChartTextObject,
    updateEChartLegendObject,
    resetEChartLegendObject,
    updateELineColorObject,
    resetELineColorObject,
    updateEBarColorObject,
    resetEBarColorObject,
    updateEChartEventObject,
    resetEChartEventObject,
    updateEChartSettingObject,
    resetEChartSettingObject,
    updateIndexObject,
    updateReportObject,
    updateTabObject,
    resetTabObject,
    updateLanguageObject,
    resetLanguageObject,
  })
)(HomeWithTab);
