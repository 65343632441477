import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import { PropTypes } from 'prop-types';

import { Link } from 'react-router-dom';

import { compose } from 'recompose';

import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

import axios from 'axios';

import { Loader } from '../common/Loader/loader';

import { store } from 'react-notifications-component';

import {
  updateCollectionObject,
  resetCollectionObject,
} from '../../actions/CollectionAction';

import { API_SERVER } from '../constant';

import './chartmenu.css';

import resetZoomIcon from '../../assets/images/reset-zoom.png';

const _ = require('lodash');

function ChartMenu(props) {
  let [labels, setLabels] = useState([]);
  let [graphData, setGraphData] = useState([]);
  let [fillColor, setFillColor] = useState({ r: 188, g: 225, b: 147, a: 0.2 });
  let [strokeColor, setStrokeColor] = useState({ r: 86, g: 133, b: 30, a: 1 });

  let [colors, setColors] = useState([]);
  let [xAxisLabel, setXaxisLabel] = useState(null);
  let [yAxisLabel, setYaxisLabel] = useState(null);
  let [graphSettings, setGraphSettings] = useState(null);
  let [completeArray, setCompleteArray] = useState([]);
  let [Loading, setLoading] = useState(false);
  let [enabledZoom, setEnabledZoom] = useState(true);
  console.log("ChartMenu props", props);
  function onGraphChartClick(e) {
    if (props.isUserSetting === 'No') {
      let url =
        'get-chart/' +
        props.userEmail +
        '/' +
        props.collectionObject.zipFileName +
        '/' +
        props.title;
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      let url =
        'get-chart/' +
        props.userEmail +
        '/' +
        props.collectionObject.zipFileName +
        '/' +
        props.title;
    }
  }

  function onUserChartClick(e) {
    if (props.isUserSetting === 'No') {
      let url =
        'get-default-chart/' +
        props.userEmail +
        '/' +
        props.collectionObject.zipFileName +
        '/' +
        props.title;
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      let url =
        'get-user-chart/' +
        props.userEmail +
        '/' +
        props.collectionObject.zipFileName +
        '/' +
        props.title;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  let _onGraphClickEvent = {
   // click: onGraphChartClick,
  };

  let _onUserChartClickEvent = {
    //click: onUserChartClick,
  };

  async function getChartDetails(data, index) {
    console.log("getChartDetails label_array", data.label_array);
    console.log("getChartDetails value_array", data.value_array);
    setCompleteArray((completeArray = []));
    setLabels((labels = data.label_array));
    setGraphData((graphData = data.value_array));
    setColors((colors = []));

    props.updateCollectionObject({ loading: false });
  }

  async function getFileSettings() {
    axios
      .post(API_SERVER + `s3-get-chart-setting-file-via-key`, {
        userEmail: props.userEmail,
        keyFile: props.title,
      })
      .then(function (response) {
        if (response.status === 200) {
          setGraphSettings((graphSettings = response.data));
          setFillColor(
            (fillColor = {
              r: graphSettings.barFillColor.r,
              g: graphSettings.barFillColor.g,
              b: graphSettings.barFillColor.b,
              a: graphSettings.barFillColor.a,
            })
          );
          setStrokeColor(
            (strokeColor = {
              r: graphSettings.barStrokeColor.r,
              g: graphSettings.barStrokeColor.g,
              b: graphSettings.barStrokeColor.b,
              a: graphSettings.barStrokeColor.a,
            })
          );
        } else {
        }
      })
      .catch(function (error) {})
      .then(function () {});
  }

  async function readSelectedZipFile(keyFile, isUserSetting) {
    console.log("readSelectedZipFile")
    let getKeyFile;
    if (props.isUserSetting === 'Yes') {
      let subFolderName = props.title;
      let lastIndex = subFolderName.lastIndexOf('/');
      let secondLastIndex = subFolderName.lastIndexOf(
        '/',
        subFolderName.lastIndexOf('/') - 1
      );
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let keyFolderName = subFolderName.substring(
        secondLastIndex + 1,
        lastIndex
      );
      let output =
        keyFolderName.substr(0, keyFolderName.lastIndexOf('.')) ||
        keyFolderName;
      getKeyFile = output;
      getFileSettings();
    } else {
      getKeyFile = props.title;
    }
    props.updateCollectionObject({ loading: true });
    let loginUEmail = props.userEmail;
    let loginUserLowerEmail = loginUEmail.replace(/[_@.]/g, '-');    
    let USER_BUCKET_NAME = loginUserLowerEmail.toLowerCase();
    axios
      .post(
        'https://eq58ppfwv2.execute-api.us-east-2.amazonaws.com/readS3ZipData',
        {
          directoryName: props.zip,
          keyFile: getKeyFile + '.json',
          userEmail: props.userEmail,
          userBucketName: USER_BUCKET_NAME,
        }
      )
      .then(async function (response) {
        console.log("Lambda api response>>", response.data.statusCode, response.data.response, response.data.response.label_array)
        if (response.data.statusCode === 200) {
          console.log("Inside success resposne")
          getChartDetails(response.data.response);
        } else {
        }
      })
      .catch(function (error) {})
      .then(function () {});
  }

  function ShowBarChart(type) {
    return (
      <ReactEcharts
        option={{
          title: {
            text:
              props.isUserSetting === 'No'
                ? props.title
                : type.settingData.title,
            textStyle: {
              color: type.settingData.titleColor,
              fontFamily: type.settingData.fontFamily,
            },
            subtext: type.settingData.subTitle,
            subtextStyle: {
              color: type.settingData.subTitleColor,
              fontFamily: type.settingData.fontFamily,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: [xAxisLabel],
            show: type.settingData.legendDisplay,
            orient: 'vertical',
            left: 'center',
            top: 'bottom',
            textStyle: {
              fontFamily: type.settingData.fontFamily,
              color: `rgba(${type.settingData.legendFontColor.r}, ${type.settingData.legendFontColor.g}, ${type.settingData.legendFontColor.b}, ${type.settingData.legendFontColor.a})`,
            },
          },
          grid: { containLabel: true },
          toolbox: {
            show: true,
            left: 'right',
            itemSize: 25,
            top: 20,
            feature: {
              restore: {
                title: 'reset',
              },
            },
          },
          dataZoom: [
            {
              type: 'inside',
              throttle: 10,
            },
          ],
          xAxis: {
            type: 'category',
            data: labels,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: xAxisLabel,
              data: graphData,
              type: 'bar',
              itemStyle: {
                color: `rgba(${type.settingData.barStrokeColor.r}, ${type.settingData.barStrokeColor.g}, ${type.settingData.barStrokeColor.b}, ${type.settingData.barStrokeColor.a})`,
              },
            },
          ],
        }}
        onEvents={_onUserChartClickEvent}
        style={{ height: '500px', width: '100%' }}
        className='echarts-for-echarts'
      />
    );
  }

  function ShowHorizonalBarChart(type) {
    return (
      <ReactEcharts
        option={{
          title: {
            text:
              props.isUserSetting === 'No'
                ? props.title
                : type.settingData.title,
            textStyle: {
              color: type.settingData.titleColor,
              fontFamily: type.settingData.fontFamily,
            },
            subtext: type.settingData.subTitle,
            subtextStyle: {
              color: type.settingData.subTitleColor,
              fontFamily: type.settingData.fontFamily,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: [xAxisLabel],
            show: type.settingData.legendDisplay,
            orient: 'vertical',
            left: 'center',
            top: 'bottom',
            textStyle: {
              fontFamily: type.settingData.fontFamily,
              color: `rgba(${type.settingData.legendFontColor.r}, ${type.settingData.legendFontColor.g}, ${type.settingData.legendFontColor.b}, ${type.settingData.legendFontColor.a})`,
            },
          },
          grid: {
            containLabel: true,
            width: 'auto',
            height: 'auto',
            left: '25px',
          },
          toolbox: {
            show: true,
            left: 'right',
            itemSize: 25,
            top: 20,
            feature: {
              restore: {
                title: 'reset',
              },
            },
          },
          dataZoom: [
            {
              type: 'inside',
              throttle: 10,
            },
          ],
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLabel: {
              interval: 0,
              rotate: 30, //If the label names are too long you can manage this by rotating the label.
            },
          },
          yAxis: {
            type: 'category',
            data: labels,
          },
          series: [
            {
              name: xAxisLabel,
              data: graphData,
              type: 'bar',
              itemStyle: {
                color: `rgba(${type.settingData.barStrokeColor.r}, ${type.settingData.barStrokeColor.g}, ${type.settingData.barStrokeColor.b}, ${type.settingData.barStrokeColor.a})`,
              },
            },
          ],
        }}
        onEvents={_onUserChartClickEvent}
        style={{ height: '500px', width: '100%' }}
        className='echarts-for-echarts'
      />
    );
  }

  function ShowPieChart(type) {
    const chartProps = {
      option: {
        title: {
          text:
            props.isUserSetting === 'No' ? props.title : type.settingData.title,
          textStyle: {
            color: type.settingData.titleColor,
            fontFamily: type.settingData.fontFamily,
          },
          subtext: type.settingData.subTitle,
          subtextStyle: {
            color: type.settingData.subTitleColor,
            fontFamily: type.settingData.fontFamily,
          },
          left: 'center',
          triggerEvent: true,
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          show: type.settingData.legendDisplay,
          orient: 'horizontal',
          left: 'center',
          top: 'bottom',
          data: labels,
          textStyle: {
            fontFamily: type.settingData.fontFamily,
            color: `rgba(${type.settingData.legendFontColor.r}, ${type.settingData.legendFontColor.g}, ${type.settingData.legendFontColor.b}, ${type.settingData.legendFontColor.a})`,
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: graphData.map((data, index) => ({
              name: labels[index],
              value: data,
            })),
            itemStyle: {
              emphasis: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0)',
              },
            },
          },
        ],
      },
      onEvents: { _onUserChartClickEvent },
      style: { height: '500px', width: '100%' },
      className: 'echarts-for-echarts',
    };
    return <ReactEcharts {...chartProps} />;
  }

  function ShowLineChart(type) {
    return (
      <ReactEcharts
        option={{
          title: {
            text:
              props.isUserSetting === 'No'
                ? props.title
                : type.settingData.title,
            textStyle: {
              color: type.settingData.titleColor,
              fontFamily: type.settingData.fontFamily,
            },
            subtext: type.settingData.subTitle,
            subtextStyle: {
              color: type.settingData.subTitleColor,
              fontFamily: type.settingData.fontFamily,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: [xAxisLabel],
            show: type.settingData.legendDisplay,
            orient: 'vertical',
            left: 'center',
            top: 'bottom',
            textStyle: {
              fontFamily: type.settingData.fontFamily,
              color: `rgba(${type.settingData.legendFontColor.r}, ${type.settingData.legendFontColor.g}, ${type.settingData.legendFontColor.b}, ${type.settingData.legendFontColor.a})`,
            },
          },
          grid: { containLabel: true },
          toolbox: {
            show: true,
            left: 'right',
            itemSize: 25,
            top: 20,
            feature: {
              restore: {
                title: 'reset',
              },
            },
          },
          dataZoom: [
            {
              type: 'inside',
              throttle: 10,
            },
          ],
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: labels,
          },
          series: [
            {
              name: xAxisLabel,
              data: graphData,
              type: 'line',
              itemStyle: {
                color: `rgba(${type.settingData.barStrokeColor.r}, ${type.settingData.barStrokeColor.g}, ${type.settingData.barStrokeColor.b}, ${type.settingData.barStrokeColor.a})`,
              },
            },
          ],
        }}
        onEvents={_onUserChartClickEvent}
        style={{ height: '500px', width: '100%' }}
        className='echarts-for-echarts'
      />
    );
  }

  function ShowDoughnutChart(type) {
    return (
      <ReactEcharts
        option={{
          title: {
            text:
              props.isUserSetting === 'No'
                ? props.title
                : type.settingData.title,
            textStyle: {
              color: type.settingData.titleColor,
              fontFamily: type.settingData.fontFamily,
            },
            subtext: type.settingData.subTitle,
            subtextStyle: {
              color: type.settingData.subTitleColor,
              fontFamily: type.settingData.fontFamily,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          legend: {
            show: type.settingData.legendDisplay,
            orient: 'horizontal',
            left: 'center',
            top: 'bottom',
            data: labels,
            textStyle: {
              fontFamily: type.settingData.fontFamily,
              color: `rgba(${type.settingData.legendFontColor.r}, ${type.settingData.legendFontColor.g}, ${type.settingData.legendFontColor.b}, ${type.settingData.legendFontColor.a})`,
            },
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '30',
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data: graphData.map((value, i) => ({ name: labels[i], value })),
            },
          ],
        }}
        onEvents={_onUserChartClickEvent}
        style={{ height: '500px', width: '100%' }}
        className='echarts-for-echarts'
      />
    );
  }

  function UserSettingGraph(type) {
    console.log("inside UserSettingGraph>>", type);
    if (type.settingData === null) {
      return null;
    } else {
      console.log("Selected Chart type", type);
      switch (type.settingData.chart_type) {
        case 'Pie':
          return <ShowPieChart settingData={type.settingData} />;
          break;
        case 'HorizontalBar':
          return <ShowHorizonalBarChart settingData={type.settingData} />;
          break;
        case 'Line':
          return <ShowLineChart settingData={type.settingData} />;
          break;
        case 'Doughnut':
          return <ShowDoughnutChart settingData={type.settingData} />;
          break;
        default:
          return <ShowBarChart settingData={type.settingData} />;
      }
    }
  }

  function DefaultShowBarChart(type) {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.title,
            textStyle: {
              color: props.defaultGraphSettings
                ? '#0038AC'
                : props.defaultGraphSettings.titleColor,
              fontFamily: props.defaultGraphSettings
                ? 'Open Sans'
                : props.defaultGraphSettings.fontFamily,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },

          legend: {
            data: [xAxisLabel],
            show: props.defaultGraphSettings
              ? 'true'
              : props.defaultGraphSettings.legendDisplay,
            orient: props.defaultGraphSettings
              ? 'vertical'
              : props.defaultGraphSettings.legendOrientation,
            left: props.defaultGraphSettings
              ? 'center'
              : props.defaultGraphSettings.legendLeftPosition,
            top: props.defaultGraphSettings
              ? 'bottom'
              : props.defaultGraphSettings.legendTopPosition,
            textStyle: {
              fontFamily: props.defaultGraphSettings
                ? 'Open Sans'
                : props.defaultGraphSettings.fontFamily,
              color: props.defaultGraphSettings
                ? 'rgba(34, 25, 77, 100)'
                : {
                    r: props.defaultGraphSettings.legendFontColor.r,
                    g: props.defaultGraphSettings.legendFontColor.g,
                    b: props.defaultGraphSettings.legendFontColor.b,
                    a: props.defaultGraphSettings.legendFontColor.a,
                  },
            },
          },
          xAxis: {
            type: 'category',
            data: labels,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: xAxisLabel,
              data: graphData,
              type: 'bar',
              itemStyle: {
                color: props.defaultGraphSettings
                  ? 'rgba(80,227,194,1)'
                  : {
                      r: props.defaultGraphSettings.barStrokeColor.r,
                      g: props.defaultGraphSettings.barStrokeColor.g,
                      b: props.defaultGraphSettings.barStrokeColor.b,
                      a: props.defaultGraphSettings.barStrokeColor.a,
                    },
              },
            },
          ],
        }}
        onEvents={_onGraphClickEvent}
        style={{ height: '500px', width: '100%' }}
        className='echarts-for-echarts'
      />
    );
  }

  function DefaultShowHorizonalBarChart(type) {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.title,
            textStyle: {
              color: props.defaultGraphSettings
                ? '#0038AC'
                : props.defaultGraphSettings.titleColor,
              fontFamily: props.defaultGraphSettings
                ? 'Open Sans'
                : props.defaultGraphSettings.fontFamily,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },

          legend: {
            data: [xAxisLabel],
            show: props.defaultGraphSettings.legendDisplay,
            orient: props.defaultGraphSettings.legendOrientation,
            left: props.defaultGraphSettings.legendLeftPosition,
            top: props.defaultGraphSettings.legendTopPosition,
            textStyle: {
              fontFamily: props.defaultGraphSettings
                ? 'Open Sans'
                : props.defaultGraphSettings.fontFamily,
              color: props.defaultGraphSettings
                ? 'rgba(34, 25, 77, 100)'
                : {
                    r: props.defaultGraphSettings.legendFontColor.r,
                    g: props.defaultGraphSettings.legendFontColor.g,
                    b: props.defaultGraphSettings.legendFontColor.b,
                    a: props.defaultGraphSettings.legendFontColor.a,
                  },
            },
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: 'category',
            data: labels,
          },
          series: [
            {
              name: xAxisLabel,
              data: graphData,
              type: 'bar',
              itemStyle: {
                color: {
                  r: props.defaultGraphSettings.barStrokeColor.r,
                  g: props.defaultGraphSettings.barStrokeColor.g,
                  b: props.defaultGraphSettings.barStrokeColor.b,
                  a: props.defaultGraphSettings.barStrokeColor.a,
                },
              },
            },
          ],
        }}
        onEvents={_onGraphClickEvent}
        style={{ height: '500px', width: '100%' }}
        className='echarts-for-echarts'
      />
    );
  }

  function DefaultShowPieChart(type) {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.title,
            textStyle: {
              color: props.defaultGraphSettings
                ? '#0038AC'
                : props.defaultGraphSettings.titleColor,
              fontFamily: props.defaultGraphSettings
                ? 'Open Sans'
                : props.defaultGraphSettings.fontFamily,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },

          legend: {
            data: [xAxisLabel],
            show: props.defaultGraphSettings
              ? 'true'
              : props.defaultGraphSettings.legendDisplay,
            orient: props.defaultGraphSettings
              ? 'vertical'
              : props.defaultGraphSettings.legendOrientation,
            left: props.defaultGraphSettings
              ? 'left'
              : props.defaultGraphSettings.legendLeftPosition,
            top: props.defaultGraphSettings
              ? 'top'
              : props.defaultGraphSettings.legendTopPosition,
            data: labels,
            textStyle: {
              fontFamily: props.defaultGraphSettings
                ? 'Open Sans'
                : props.defaultGraphSettings.fontFamily,
              color: props.defaultGraphSettings
                ? 'rgba(34, 25, 77, 100)'
                : {
                    r: props.defaultGraphSettings.legendFontColor.r,
                    g: props.defaultGraphSettings.legendFontColor.g,
                    b: props.defaultGraphSettings.legendFontColor.b,
                    a: props.defaultGraphSettings.legendFontColor.a,
                  },
            },
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: '55%',
              center: ['50%', '60%'],
              data: completeArray,
              itemStyle: {
                emphasis: {
                  shadowBlur: 0,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0)',
                },
              },
            },
          ],
        }}
        onEvents={_onGraphClickEvent}
        style={{ height: '500px', width: '100%' }}
        className='echarts-for-echarts'
      />
    );
  }

  function DefaultShowLineChart(type) {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.title,
            textStyle: {
              color: props.defaultGraphSettings
                ? '#0038AC'
                : props.defaultGraphSettings.titleColor,
              fontFamily: props.defaultGraphSettings
                ? 'Open Sans'
                : props.defaultGraphSettings.fontFamily,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },

          legend: {
            data: [xAxisLabel],
            show: props.defaultGraphSettings
              ? 'true'
              : props.defaultGraphSettings.legendDisplay,
            orient: props.defaultGraphSettings
              ? 'vertical'
              : props.defaultGraphSettings.legendOrientation,
            left: props.defaultGraphSettings
              ? 'center'
              : props.defaultGraphSettings.legendLeftPosition,
            top: props.defaultGraphSettings
              ? 'bottom'
              : props.defaultGraphSettings.legendTopPosition,
            textStyle: {
              fontFamily: props.defaultGraphSettings
                ? 'Open Sans'
                : props.defaultGraphSettings.fontFamily,
              color: props.defaultGraphSettings
                ? 'rgba(34, 25, 77, 100)'
                : {
                    r: props.defaultGraphSettings.legendFontColor.r,
                    g: props.defaultGraphSettings.legendFontColor.g,
                    b: props.defaultGraphSettings.legendFontColor.b,
                    a: props.defaultGraphSettings.legendFontColor.a,
                  },
            },
          },
          xAxis: {
            type: 'category',
            data: labels,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: xAxisLabel,
              data: graphData,
              type: 'line',
              itemStyle: {
                color: props.defaultGraphSettings
                  ? 'rgba(80,227,194,1)'
                  : {
                      r: props.defaultGraphSettings.barStrokeColor.r,
                      g: props.defaultGraphSettings.barStrokeColor.g,
                      b: props.defaultGraphSettings.barStrokeColor.b,
                      a: props.defaultGraphSettings.barStrokeColor.a,
                    },
              },
            },
          ],
        }}
        onEvents={_onGraphClickEvent}
        style={{ height: '500px', width: '100%' }}
        className='echarts-for-echarts'
      />
    );
  }

  function DefaultUserSettingGraph(type) {
    console.log("inside DefaultUserSettingGraph>>", type);
    if (type.settingData === null) {
      return null;
    } else {
      switch (type.settingData.chart_type) {
        case 'Pie':
          return <DefaultShowPieChart settingData={type.settingData} />;
          break;
        case 'HorizontalBar':
          return (
            <DefaultShowHorizonalBarChart settingData={type.settingData} />
          );
          break;
        case 'Line':
          return <DefaultShowLineChart settingData={type.settingData} />;
          break;
        default:
          return <DefaultShowBarChart settingData={type.settingData} />;
      }
    }
  }

  function resetZoom(event) {
    setEnabledZoom((enabledZoom = false));
    setTimeout(() => {
      setEnabledZoom((enabledZoom = true));
    }, 1500);
  }

  useEffect(() => {
    if (props.title) {
      readSelectedZipFile(props.title, props.isUserSetting);
    } else {
    }
  }, [props.title]);

  return (
    <React.Fragment>
      <div className='boxchart'>
        <div
          className='chart-img chart-img2'
          style={{ height: '500px', width: '550px', position: 'relative' }}
        >
          {props.isUserSetting === 'No' ? (
            <UserSettingGraph settingData={props.defaultGraphSettings} />
          ) : (
            <UserSettingGraph settingData={graphSettings} />
          )}{' '}
          <span className='chartIndexDisplay'>
            {props.currentChartIndex + 1}/{props.totalChartIndex}
          </span>
          {Loading && <Loader />}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const collectionObject = reducerObj.collectionReducers.collectionObject;
  return { collectionObject };
};

export default compose(
  connect(mapStateToProps, {
    updateCollectionObject,
    resetCollectionObject,
  })
)(ChartMenu);
