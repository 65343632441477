import React, { useState, useEffect, useRef, useCallback } from 'react';

import axios from 'axios';

import { connect } from 'react-redux';

import { PropTypes } from 'prop-types';

import { Link } from 'react-router-dom';

import { compose } from 'recompose';

import ReactTooltip from 'react-tooltip';
import { DownOutlined, UserOutlined, PlusCircleOutlined, SettingOutlined, QuestionCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';

import { Auth } from 'aws-amplify';

import html2canvas from 'html2canvas';

import { SketchPicker } from 'react-color';

import reactCSS from 'reactcss';

import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '../common/Loader/loader';

import { store } from 'react-notifications-component';

import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';

import PrintModal from './PrintModal';

import ReportModal from './ReportModal';

import HelpModal from './HelpModal';

import { useTranslation } from 'react-i18next';

import { API_SERVER } from '../constant';
import { checkUserSession } from '../common/Helper/helper';
import {
  updateCollectionObject,
  resetCollectionObject,
} from '../../actions/CollectionAction';

import settingIcon from '../../assets/images/setting.png';
import plusSignBlue from "../../assets/images/PlusSign_blue.png";

import ChartMenu from './ChartMenu';

import './ziplist.css';

const _ = require('lodash');

const pdfConverter = require('jspdf');

const MySwal = withReactContent(Swal);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function ZipList(props) {
  const { t, i18n } = useTranslation('common');

  let [expand, setExpanded] = useState(false);
  let [show, setShow] = useState(false);
  let [Loading, setLoading] = useState(false);
  let [isFetching, setIsFetching] = useState(false);

  let [userEmail, setUserEmail] = useState(null);
  let [chartHeader, setChartHeader] = useState(null);

  let [labels, setLabels] = useState([]);
  let [graphData, setGraphData] = useState([]);
  let [colors, setColors] = useState([]);
  let [xAxisLabel, setXaxisLabel] = useState(null);
  let [yAxisLabel, setYaxisLabel] = useState(null);
  let [completeArray, setCompleteArray] = useState([]);

  let [downloadType, setDownloadType] = useState('PDF');

  let [showPrintPopup, setShowPrintPopup] = useState(false);
  let [printPopUpData, setPrintPopUpData] = useState(null);

  let [showReportPopup, setShowReportPopup] = useState(false);
  let [reportPopUpData, setReportPopUpData] = useState([]);

  let [showHelpPopup, setShowHelpPopup] = useState(false);

  let [firstitems, setFirstItems] = useState([]);
  let [seconditems, setSecondItems] = useState([]);
  let [selectedZip, setSelectedZip] = useState(null);
  let [directoryName, setDirectoryName] = useState(null);

  let [zipFiles, setZipFiles] = useState([]);
  let [wholeZipFiles, setWholeZipFiles] = useState([]);
  let [userSettingFiles, setUserSettingFiles] = useState([]);

  let [displayedFileIndex, setDisplayedFileIndex] = useState(0);
  let [totalFileIndex, setTotalFileIndex] = useState(0);

  let [hasMore, sethasMore] = useState(false);
  let [graphSettings, setGraphSettings] = useState(null);

  let [scrollChartCount, setScrollChartCount] = useState(4);

  const style = {
    height: 500,
  };

  function handleScrollChartCountChange(e) {
    setScrollChartCount(parseInt(e.target.value, 10));
  }

  function toggleSidebar() {
    setExpanded((expand = !expand));
  }

  function toggleGraph() {
    //setShow(show=!show);
  }

  function handleDownloadTypeChange(e) {
    setDownloadType((downloadType = e.target.value));
  }

  const handleMenuClick = (e) => {
    //message.info('Click on menu item.');
    console.log('click', e);
    if(e.key=="openreport"){
      openReportPopup();
    } else if(e.key=="formatreport"){
      toggleGraph();
    }
    else if(e.key=="download"){
      openPrintPopup();
    }
    else if(e.key=="help"){
      openHelpPopup();
    }
    
    
  };
  const items = [
    {
      label: t('ChartOption.Select_Chart'),
      key: 'openreport',
      icon: <PlusCircleOutlined />,
      
    },    
    {
      label: t('ChartOption.Chart_Help'),
      key: 'help',
      icon: <QuestionCircleOutlined />,      
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  async function getUserDetails() {
    if(props.toolTipObject.toolTip===false) {   //Show success message
      store.addNotification({
        title: t('SuccessMessage.done'),
        message: t('SuccessMessage.pleaseWaitWhileWefetch'),
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'zoomIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
  }
    let userInfo = await Auth.currentUserInfo();
    if (userInfo != null) {
      setUserEmail((userEmail = userInfo.attributes.email));
      
    }
    await getUploadedFileList();
  }

  async function getUploadedFileList() {
   await axios
      .post(API_SERVER + `s3-file-read-complete-folder`, {
        userEmail: userEmail,
        sortOrder: 'asc',
        directoryName:''
      })
      .then(async function (response) {
        console.log(response, '=test');
        if (response.status === 200) {
          console.log("getUploadedFileList",response.data.data)
          //setReportPopUpData((reportPopUpData = response.data.data));

          //fetch zip file
         await axios
          .post(API_SERVER + `s3-zip-file-list-with-file-count-new-content`, {
            userEmail: userEmail,
            sortOrder: 'asc',
            directoryName:'',
            completeFiles: response.data.data,
            showErrorFileStatus: true

          })
          .then(function (response1) {
            if (response.status === 200) {
              console.log("kpi-new-zip",response1.data.data)
              setReportPopUpData((reportPopUpData = response1.data.data));
            } else {
            }
          })
          .catch(function (error) {})
          .then(function () {
            setLoading(false);
          });
          //fetch zip file

          let zipListData = JSON.parse(localStorage.getItem('zipListData'));

          if (zipListData === null) {
            setShowReportPopup((showReportPopup = true));
          } else {
            Swal.fire({
              title: t('SuccessMessage.PreviousConfigurationDetected'),
              text: t('SuccessMessage.Doyourenderselection'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: t('SuccessMessage.yes'),
              cancelButtonText: t('SuccessMessage.no'),
            }).then((result) => {
              console.log("confirmed popup: ", result)
              if (result.isConfirmed) {
                console.log("Inside result-isConfirmed")
                Swal.fire({
                  title: t('SuccessMessage.Pleasewaitwhileprepare'),
                  icon: 'info',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                  confirmButtonText:
                    '<i class="fa fa-thumbs-up"></i> ' +
                    t('SuccessMessage.great') +
                    '!',
                  confirmButtonAriaLabel: 'Thumbs up, great!',
                  timer: 3000,
                });

                let zipName = zipListData.zipName;
                let zipFileList = zipListData.zipFileList;
                let dir = zipListData.dir;
                let type = zipListData.type;
                let userSetting = zipListData.userSetting;
                let selectedKey = zipListData.selectedKey;

                setDirectoryName((directoryName = null));
                setSelectedZip((selectedZip = null));
                setZipFiles((zipFiles = []));
                setWholeZipFiles((wholeZipFiles = []));
                setUserSettingFiles((userSettingFiles = []));
                setFirstItems((firstitems = []));
                setSecondItems((seconditems = []));
                setDisplayedFileIndex((displayedFileIndex = 0));
                setTotalFileIndex((totalFileIndex = 0));
                sethasMore((hasMore = false));
                props.updateCollectionObject({ zipFileName: '' });
                props.updateCollectionObject({ zipPath: '' });
                props.updateCollectionObject({ currentIndex: 0 });
                props.updateCollectionObject({ totalFileIndex: 0 });
                props.updateCollectionObject({ hasListEnded: false });
                if (zipName != null) {
                  if (selectedKey === 'ALL') {
                    setSelectedZip((selectedZip = zipName));
                    setDirectoryName((directoryName = dir));
                    setZipFiles((zipFiles = zipFileList));
                    setUserSettingFiles((userSettingFiles = userSetting));
                    let combinedArray = userSettingFiles.concat(zipFiles);
                    setWholeZipFiles((wholeZipFiles = combinedArray));
                    let currentFirstArray = [];
                    let promises = wholeZipFiles.map(async (item, index) => {
                      if (index < 4) {
                        currentFirstArray.push(item);
                        setDisplayedFileIndex((displayedFileIndex = index));
                      }
                    });
                    setFirstItems((firstitems = currentFirstArray));
                    setTotalFileIndex((totalFileIndex = wholeZipFiles.length));
                    if (firstitems.length < 4) {
                      sethasMore((hasMore = false));
                    } else {
                      sethasMore((hasMore = true));
                    }
                    props.updateCollectionObject({ zipFileName: dir });
                    props.updateCollectionObject({ zipPath: zipName });
                    props.updateCollectionObject({
                      currentIndex: displayedFileIndex,
                    });
                    props.updateCollectionObject({
                      totalFileIndex: totalFileIndex,
                    });
                    props.updateCollectionObject({ hasListEnded: hasMore });
                  } else if (selectedKey === 'USER_REPORTS') {
                    setSelectedZip((selectedZip = zipName));
                    setDirectoryName((directoryName = dir));
                    setUserSettingFiles((userSettingFiles = userSetting));
                    let currentFirstArray = [];
                    let promises = userSettingFiles.map(async (item, index) => {
                      if (index < 4) {
                        currentFirstArray.push(item);
                        setDisplayedFileIndex((displayedFileIndex = index));
                      }
                    });
                    setFirstItems((firstitems = currentFirstArray));
                    setTotalFileIndex(
                      (totalFileIndex = userSettingFiles.length)
                    );
                    if (firstitems.length < 4) {
                      sethasMore((hasMore = false));
                    } else {
                      sethasMore((hasMore = true));
                    }
                    props.updateCollectionObject({ zipFileName: dir });
                    props.updateCollectionObject({ zipPath: zipName });
                    props.updateCollectionObject({
                      currentIndex: displayedFileIndex,
                    });
                    props.updateCollectionObject({
                      totalFileIndex: totalFileIndex,
                    });
                    props.updateCollectionObject({ hasListEnded: hasMore });
                  } else if (selectedKey === 'SELECTED_REPORT') {
                    setSelectedZip((selectedZip = zipName));
                    setDirectoryName((directoryName = dir));
                    setZipFiles((zipFiles = zipFileList));
                    setUserSettingFiles((userSettingFiles = userSetting));
                    let combinedArray = userSettingFiles.concat(zipFiles);
                    setWholeZipFiles((wholeZipFiles = combinedArray));
                    //setWholeZipFiles(wholeZipFiles=zipFiles);
                    let currentFirstArray = [];
                    let promises = wholeZipFiles.map(async (item, index) => {
                      if (index < 4) {
                        currentFirstArray.push(item);
                        setDisplayedFileIndex((displayedFileIndex = index));
                      }
                    });
                    setFirstItems((firstitems = currentFirstArray));
                    setTotalFileIndex((totalFileIndex = wholeZipFiles.length));
                    if (firstitems.length < 4) {
                      sethasMore((hasMore = false));
                    } else {
                      sethasMore((hasMore = true));
                    }
                    props.updateCollectionObject({ zipFileName: dir });
                    props.updateCollectionObject({ zipPath: zipName });
                    props.updateCollectionObject({
                      currentIndex: displayedFileIndex,
                    });
                    props.updateCollectionObject({
                      totalFileIndex: totalFileIndex,
                    });
                    props.updateCollectionObject({ hasListEnded: hasMore });
                  } else {
                  }
                }
              } else {
                Swal.fire(
                  t('SuccessMessage.done'),
                  t('Modal.SelectSourceFileandReport'),
                  'success'
                );
              }
            });
          }
        } else {
          store.addNotification({
            title: t('ErrorMessage.error'),
            message: t('ErrorMessage.error'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: t('ErrorMessage.error'),
          message: t('ErrorMessage.error'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
        getDefaultSettingsNow();
      });
  }

  async function getDefaultSettingsNow() {
    console.log("inside getDefaultSettingsNow");
    axios
      .post(API_SERVER + `s3-get-chart-default-setting`, {
        userEmail: userEmail,
      })
      .then(function (response) {
        if (response.status === 200) {
          setGraphSettings((graphSettings = response.data));
        } else {
        }
      })
      .catch(function (error) {})
      .then(function () {
        setLoading(false);
      });
  }

  /*** HANDLE POPUP ***/

  const openPrintPopup = () => {
    setShowPrintPopup((showPrintPopup = true));
  };

  const closePrintPopup = () => {
    setShowPrintPopup((showPrintPopup = false));
  };

  const onPrintModalCloseAfterApi = (event) => {
    setLoading(true);
    if (event === 'PDF') {
      let input = document.getElementsByClassName('my-chart-img')[0];

      html2canvas(input).then((canvas) => {
        let imgData = canvas.toDataURL('image/png');

        const pdf2 = new pdfConverter({
          orientation: 'landscape',
        });
        const imgProps = pdf2.getImageProperties(imgData);
        const pdfWidth = pdf2.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf2.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf2.save(chartHeader + `.pdf`);
      });
    } else if (event === 'JPEG') {
      let input = document.getElementsByClassName('my-chart-img')[0];

      html2canvas(input).then((canvas) => {
        let canvasImage = canvas.toDataURL('image/png');

        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(xhr.response);
          a.download = chartHeader + `.jpeg`;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          a.remove();
        };
        xhr.open('GET', canvasImage); // This is to download the canvas Image
        xhr.send();
      });
    } else {
      downloadSelectedJsonFile();
    }
    setLoading(false);
  };

  const openReportPopup = () => {
    setShowReportPopup((showReportPopup = true));
  };

  const closeReportPopup = () => {
    setShowReportPopup((showReportPopup = false));
  };

  const onReportModalCloseAfterApi = (
    zipName,
    zipFileList,
    dir,
    type,
    userSetting,
    selectedKey,
    selectedValue
  ) => {
    setDirectoryName((directoryName = null));
    setSelectedZip((selectedZip = null));
    setZipFiles((zipFiles = []));
    setWholeZipFiles((wholeZipFiles = []));
    setUserSettingFiles((userSettingFiles = []));
    setFirstItems((firstitems = []));
    setSecondItems((seconditems = []));
    setDisplayedFileIndex((displayedFileIndex = 0));
    setTotalFileIndex((totalFileIndex = 0));
    sethasMore((hasMore = false));
    props.updateCollectionObject({ zipFileName: '' });
    props.updateCollectionObject({ zipPath: '' });
    props.updateCollectionObject({ currentIndex: 0 });
    props.updateCollectionObject({ totalFileIndex: 0 });
    props.updateCollectionObject({ hasListEnded: false });
    if (zipName != null) {
      if (selectedKey === 'ALL') {
        setSelectedZip((selectedZip = zipName));
        setDirectoryName((directoryName = dir));
        setZipFiles((zipFiles = zipFileList));
        setUserSettingFiles((userSettingFiles = userSetting));
        let combinedArray = userSettingFiles.concat(zipFiles);
        console.log("combinedArray>>",combinedArray);
        setWholeZipFiles((wholeZipFiles = combinedArray));
        let currentFirstArray = [];
        console.log("wholeZipFiles length: ", wholeZipFiles.length)
        let promises = wholeZipFiles.map(async (item, index) => {
          if (index < 4) {
            currentFirstArray.push(item);
            setDisplayedFileIndex((displayedFileIndex = index));
          }
        });
        setFirstItems((firstitems = currentFirstArray));
        setTotalFileIndex((totalFileIndex = wholeZipFiles.length));
        console.log("firstitems.length>>",firstitems.length)
        if (firstitems.length < 4) {
          sethasMore((hasMore = false));
        } else {
          sethasMore((hasMore = true));
        }
        props.updateCollectionObject({ zipFileName: dir });
        props.updateCollectionObject({ zipPath: zipName });
        props.updateCollectionObject({ currentIndex: displayedFileIndex });
        props.updateCollectionObject({ totalFileIndex: totalFileIndex });
        props.updateCollectionObject({ hasListEnded: hasMore });
      } else if (selectedKey === 'USER_REPORTS') {
        setSelectedZip((selectedZip = zipName));
        setDirectoryName((directoryName = dir));
        setUserSettingFiles((userSettingFiles = userSetting));
        let currentFirstArray = [];
        let promises = userSettingFiles.map(async (item, index) => {
          if (index < 4) {
            currentFirstArray.push(item);
            setDisplayedFileIndex((displayedFileIndex = index));
          }
        });
        setFirstItems((firstitems = currentFirstArray));
        setTotalFileIndex((totalFileIndex = userSettingFiles.length));
        if (firstitems.length < 4) {
          sethasMore((hasMore = false));
        } else {
          sethasMore((hasMore = true));
        }
        props.updateCollectionObject({ zipFileName: dir });
        props.updateCollectionObject({ zipPath: zipName });
        props.updateCollectionObject({ currentIndex: displayedFileIndex });
        props.updateCollectionObject({ totalFileIndex: totalFileIndex });
        props.updateCollectionObject({ hasListEnded: hasMore });
      } else if (selectedKey === 'SELECTED_REPORT') {
        setSelectedZip((selectedZip = zipName));
        setDirectoryName((directoryName = dir));
        setZipFiles((zipFiles = zipFileList));
        setUserSettingFiles((userSettingFiles = userSetting));
        let combinedArray = userSettingFiles.concat(zipFiles);
        setWholeZipFiles((wholeZipFiles = combinedArray));
        //setWholeZipFiles(wholeZipFiles=zipFiles);
        let currentFirstArray = [];
        let promises = wholeZipFiles.map(async (item, index) => {
          if (index < 4) {
            currentFirstArray.push(item);
            setDisplayedFileIndex((displayedFileIndex = index));
          }
        });
        setFirstItems((firstitems = currentFirstArray));
        setTotalFileIndex((totalFileIndex = wholeZipFiles.length));
        if (firstitems.length < 4) {
          sethasMore((hasMore = false));
        } else {
          sethasMore((hasMore = true));
        }
        props.updateCollectionObject({ zipFileName: dir });
        props.updateCollectionObject({ zipPath: zipName });
        props.updateCollectionObject({ currentIndex: displayedFileIndex });
        props.updateCollectionObject({ totalFileIndex: totalFileIndex });
        props.updateCollectionObject({ hasListEnded: hasMore });
      } else {
      }
      localStorage.removeItem('zipListData');
      let storageData = {
        zipName: zipName,
        zipFileList: zipFileList,
        dir: dir,
        type: type,
        userSetting: userSetting,
        selectedKey: selectedKey,
      };
      localStorage.setItem('zipListData', JSON.stringify(storageData));

      Swal.fire({
        title: t('SuccessMessage.Pleasewaitwhileprepare'),
        icon: 'info',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        timer: 3000,
      });
    } else {
      setGraphData((graphData = []));
    }
  };

  const openHelpPopup = () => {
    setShowHelpPopup((showHelpPopup = true));
  };

  const closeHelpPopup = () => {
    setShowHelpPopup((showHelpPopup = false));
  };

  async function downloadSelectedJsonFile() {
    setLoading(true);
    axios
      .post(API_SERVER + `s3-zip-file-get-content`, {
        userEmail: userEmail,
        directoryName: props.indexObject.key,
        fileName: props.reportObject.key,
      })
      .then(function (response) {
        if (response.status === 200) {
          const fileData = JSON.stringify(response.data);
          console.log("s3-zip-file-get-content api response: " + fileData);
          const blob = new Blob([fileData], { type: 'text/plain' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.download = props.reportObject.value;
          link.href = url;
          link.click();
        } else {
        }
      })
      .catch(function (error) {})
      .then(function () {
        setLoading(false);
      });
  }

  const fetchMoreData = async () => {
    if (Loading || !hasMore) return;
    if (displayedFileIndex + 1 >= totalFileIndex) {
      sethasMore((hasMore = false));
      return;
    } else {
      props.updateCollectionObject({ Loading: true });
      if (wholeZipFiles.length === 0) {
        if (userSettingFiles.length > 0) {
          let currentFirstArray = [...firstitems];
          let newZipFiles = userSettingFiles.slice(
            displayedFileIndex + 1,
            totalFileIndex
          );
          let promises = newZipFiles.map(async (item, index) => {
            if (index < scrollChartCount) {
              currentFirstArray.push(item);
              let loopIndex = displayedFileIndex + index + 1;
              setDisplayedFileIndex((displayedFileIndex = loopIndex));
            }
          });
          setFirstItems((firstitems = currentFirstArray));
        } else {
          let currentFirstArray = [...firstitems];
          let newZipFiles = zipFiles.slice(
            displayedFileIndex + 1,
            totalFileIndex
          );
          let promises = newZipFiles.map(async (item, index) => {
            if (index < scrollChartCount) {
              currentFirstArray.push(item);
              let loopIndex = displayedFileIndex + index + 1;
              setDisplayedFileIndex((displayedFileIndex = loopIndex));
            }
          });
          setFirstItems((firstitems = currentFirstArray));
        }

        // Swal.fire({
        //   title: t('SuccessMessage.Pleasewaitwhileprepare'),
        //   icon: 'info',
        //   showClass: {
        //     popup: 'animate__animated animate__fadeInDown',
        //   },
        //   hideClass: {
        //     popup: 'animate__animated animate__fadeOutUp',
        //   },
        //   confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
        //   confirmButtonAriaLabel: 'Thumbs up, great!',
        //   timer: 3000,
        // });
      } else {
        let currentFirstArray = [...firstitems];
        let newZipFiles = wholeZipFiles.slice(
          displayedFileIndex + 1,
          totalFileIndex
        );
        let promises = newZipFiles.map(async (item, index) => {
          if (index < scrollChartCount) {
            currentFirstArray.push(item);
            //let loopIndex = displayedFileIndex+index+1;
            let loopIndex = displayedFileIndex + 1;
            setDisplayedFileIndex((displayedFileIndex = loopIndex));
          }
        });
        setFirstItems((firstitems = currentFirstArray));

        // Swal.fire({
        //   title: t('SuccessMessage.Pleasewaitwhileprepare'),
        //   icon: 'info',
        //   showClass: {
        //     popup: 'animate__animated animate__fadeInDown',
        //   },
        //   hideClass: {
        //     popup: 'animate__animated animate__fadeOutUp',
        //   },
        //   confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
        //   confirmButtonAriaLabel: 'Thumbs up, great!',
        //   timer: 3000,
        // });
      }
    }
  };
  // const handleScroll = useCallback(
  //   _.debounce(() => {
  //     const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
  //     if (scrollHeight - scrollTop <= clientHeight + 50) {
  //       fetchMoreData();
  //     }
  //   }, 300),
  //   [fetchMoreData]
  // );
  // useEffect(() => {
  //   const scrollableDiv = document.getElementById('scrollableDiv');
  //   if (scrollableDiv) {
  //     scrollableDiv.addEventListener('scroll', handleScroll);
  //   }
  //   return () => {
  //     if (scrollableDiv) {
  //       scrollableDiv.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  // }, [handleScroll]);
  const handleScroll = useCallback(
    _.debounce(() => {
      const { scrollTop, scrollHeight, clientHeight } = document.getElementById('scrollableDiv');
      if (scrollHeight - scrollTop <= clientHeight + 50) {
        fetchMoreData();
      }
    }, 300),
    [fetchMoreData]
  );

  useEffect(() => {
    //window.addEventListener('scroll', handleScroll);
    const scrollableDiv = document.getElementById('scrollableDiv');
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => scrollableDiv.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    checkUserSession();
    setLoading(true);
    getUserDetails();
  }, []);

  return (
    <React.Fragment>
      <PrintModal
        showPrintPopup={showPrintPopup}
        closePrintPopup={closePrintPopup}
        onPrintModalCloseAfterApi={onPrintModalCloseAfterApi}
        printPopUpData={printPopUpData}
      />
      <HelpModal
        showHelpPopup={showHelpPopup}
        closeHelpPopup={closeHelpPopup}
      />
      <ReportModal
        showReportPopup={showReportPopup}
        closeReportPopup={closeReportPopup}
        onReportModalCloseAfterApi={onReportModalCloseAfterApi}
        reportPopUpData={reportPopUpData}
        userEmail={userEmail}
      />
      <div id='wrapper' className={expand ? 'menu-active' : 'menu'}>
        <div id='page-content-wrapper-tab'>
          <div
            className='page-content inset-tab'
            data-spy='scroll'
            data-target='#spy'
          >
            <div className='container-full zippg'>
              <div className='boxWrapper'>
                <div className='page-content-row'>
                  <div className='row'>
                    <div
                      className={
                        !show ? 'col-md-12 rightspac' : 'col-md-9 rightspac'
                      }
                    >
                     {/* <div
                        className='lftHandCursor'
                        data-tip
                        data-for='selectReportTip'
                        onClick={(e) => openReportPopup()}
                      >
                        <img src={plusSignBlue} alt="plusSignBlue" />
                         <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='60'
                          height='63'
                          viewBox='0 0 514.186 577.554'
                        >
                          <g
                            id='cursor'
                            transform='matrix(0.259, 0.966, -0.966, 0.259, 457.744, -22.176)'
                          >
                            <g id='Group_2' data-name='Group 2'>
                              <g id='Group_1' data-name='Group 1'>
                                <path
                                  id='Path_1'
                                  data-name='Path 1'
                                  d='M461.63,251.914l-15.038-34.207c-.89-2.026-1.879-4.035-2.938-5.968A52.313,52.313,0,0,0,376.889,189l-5.8,2.52-7.4-16.845,0-.007-5.456-12.41A43.12,43.12,0,0,0,301.236,140.2l-19.228,8.33A35.988,35.988,0,0,0,269.094,137c-10.521-5.445-23.178-4.987-37.621,1.363l-13.759,6.048L199.9,103.9c-7.25-16.493-20.706-27.81-36.916-31.05-15.239-3.046-30.755,1.265-41.51,11.53a51.818,51.818,0,0,0-11.822,58.9L177.122,297.71a79.493,79.493,0,0,0-17.643-1.519c-15.691.387-29.8,5.377-39.247,13.79a10.007,10.007,0,0,0-1.737,1.428c-9.494,9.777-14.174,21.6-13.537,34.2.8,15.743,9.79,31.268,26.006,44.9.082.069.165.137.25.2L269.9,499.858a10,10,0,0,0,10.209,1.3l198.672-87.337a10,10,0,0,0,5.956-8.531l1.081-17.312A294,294,0,0,0,461.63,251.914Zm4.223,134.814-.7,11.231-187.6,82.472L143.715,375.1c-11.612-9.8-18.28-20.623-18.78-30.5-.324-6.4,1.87-12.364,6.526-17.748a10.133,10.133,0,0,0,.964-.873c11.971-12.329,41.824-13.181,57.414-1.748l34.447,33.084a10,10,0,0,0,13.855-14.425L204.625,310.7,127.977,135.246c-6.717-15.28-.627-28.821,7.313-36.4,5.953-5.682,15.066-8.129,23.782-6.386,9.87,1.973,17.87,8.893,22.527,19.486l58.06,132.072a10,10,0,0,0,18.309-8.049l-32.2-73.249,13.76-6.049c8.636-3.795,15.493-4.438,20.379-1.909,3.458,1.79,6.373,5.338,8.663,10.546l34.24,77.888a10,10,0,0,0,18.309-8.049l-30.194-68.685,18.32-7.937c.039-.017.077-.033.115-.051a23.121,23.121,0,0,1,30.567,11.832l40.687,92.565a10,10,0,0,0,18.309-8.049l-19.779-44.992,5.722-2.488a32.129,32.129,0,0,1,41.244,13.994c.783,1.431,1.514,2.916,2.173,4.416l15.039,34.208A273.945,273.945,0,0,1,465.853,386.728Z'
                                />
                                <path
                                  id='Path_2'
                                  data-name='Path 2'
                                  d='M426.561,384.075,416.7,388.4a10,10,0,1,0,8.033,18.315l9.865-4.327a10,10,0,0,0-8.033-18.315Z'
                                />
                                <path
                                  id='Path_3'
                                  data-name='Path 3'
                                  d='M386.77,401.751,281.417,447.959a10,10,0,1,0,8.033,18.316L394.8,420.066a10,10,0,0,0-8.033-18.315Z'
                                />
                                <path
                                  id='Path_4'
                                  data-name='Path 4'
                                  d='M127.017,45.2A10,10,0,1,0,146.6,41.153l-6.85-33.174a10,10,0,1,0-19.586,4.044Z'
                                />
                                <path
                                  id='Path_5'
                                  data-name='Path 5'
                                  d='M41.035,46.913A10,10,0,0,0,37.8,33.146l-6.907-4.277a10,10,0,1,0-10.531,17l6.907,4.277a10,10,0,0,0,13.767-3.236Z'
                                />
                                <path
                                  id='Path_6'
                                  data-name='Path 6'
                                  d='M60.454,72.245,81.786,86.276a10,10,0,1,0,10.991-16.71L71.444,55.535a10,10,0,0,0-10.99,16.71Z'
                                />
                                <path
                                  id='Path_7'
                                  data-name='Path 7'
                                  d='M86.622,135.491a10,10,0,0,0-11.816-7.771l-33.174,6.85a10,10,0,0,0,2.012,19.795,10.109,10.109,0,0,0,2.033-.208l33.174-6.85A10,10,0,0,0,86.622,135.491Z'
                                />
                                <path
                                  id='Path_8'
                                  data-name='Path 8'
                                  d='M111.775,186.982a10,10,0,0,0-13.85,2.86l-18.614,28.3a10,10,0,1,0,16.71,10.99l18.614-28.3A10,10,0,0,0,111.775,186.982Z'
                                />
                                <path
                                  id='Path_9'
                                  data-name='Path 9'
                                  d='M221.355,105.18a10,10,0,0,0,9.783,7.979,10.116,10.116,0,0,0,2.033-.208l22.116-4.567A10,10,0,1,0,251.242,88.8l-22.116,4.567A10,10,0,0,0,221.355,105.18Z'
                                />
                                <path
                                  id='Path_10'
                                  data-name='Path 10'
                                  d='M293.34,100.315a10.109,10.109,0,0,0,2.033-.208l6.912-1.427a10,10,0,1,0-4.045-19.587l-6.912,1.427a10,10,0,0,0,2.012,19.795Z'
                                />
                                <path
                                  id='Path_11'
                                  data-name='Path 11'
                                  d='M190.907,61.982a10,10,0,0,0,13.85-2.86l18.614-28.3a10,10,0,0,0-16.71-10.99l-18.614,28.3A10,10,0,0,0,190.907,61.982Z'
                                />
                              </g>
                            </g>
                          </g>
                        </svg> 
                      </div>*/}
                      
                      <div className='left-side-boxxx'>
                      <div className='card-header' style={{width:'100%'}}>
                          <div class="text-align-left">
                          {/*chartSelectedDataLabel.fileDetails.label*/}
                          </div>
                          <div class="text-align-right">
                              <Dropdown menu={menuProps}>
                                <Button>
                                  <Space>
                                    Chart Options
                                    <DownOutlined />
                                  </Space>
                                </Button>
                              </Dropdown>
                            </div>
                          </div>
                        <div className='my-chart-img'>
                          {
                            <div className='resp-scrollableDiv' id='scrollableDiv'>
                              <div className='chart-repeat-div row'>
                                {firstitems.length > 0
                                  ? firstitems.map((item, index) => {
                                      return (
                                        <React.Fragment>
                                          <div
                                            className='col-md-6 firstChart'
                                            key={index}
                                          >
                                            <ChartMenu
                                              currentChartIndex={index}
                                              totalChartIndex={totalFileIndex}
                                              zip={selectedZip}
                                              key={index}
                                              title={item}
                                              defaultGraphSettings={
                                                graphSettings
                                              }
                                              userEmail={userEmail}
                                              isUserSetting={
                                                item.lastIndexOf('/') > 0
                                                  ? 'Yes'
                                                  : 'No'
                                              }
                                            />
                                          </div>
                                        </React.Fragment>
                                      );
                                    })
                                  : t('ChartOption.ChartSelectMSG')}
                              </div>
                              {/* {hasMore && (
                                <div className='loadMorePanel'>
                                  <div className='btnloadmore'>
                                    <button
                                      className='btn loadmore'
                                      onClick={fetchMoreData}
                                    >
                                      {t('Button.loading')}
                                    </button>
                                  </div>
                                  <div className='selectItemNo'>
                                    <div className='styled-select relativePosition'>
                                      <select
                                        value={scrollChartCount}
                                        onChange={handleScrollChartCountChange}
                                        className='form-control'
                                        id='exampleFormControlSelect1'
                                      >
                                        <option value='4'>4</option>
                                        <option value='6'>6</option>
                                        <option value='8'>8</option>
                                      </select>
                                      <div className='Hideselect'></div>
                                    </div>
                                  </div>
                                </div>
                              )} */}
                              {!hasMore && firstitems.length > 0 && (
                                <p
                                  style={{
                                    textAlign: 'center',
                                    marginTop: '20px',
                                  }}
                                >
                                  <b>{t('Text.youSeenItAll')}</b>
                                </p>
                              )}
                            </div>
                          }
                        </div>
                      </div>
                     {/* <div
                        className='leftHelpBottom'
                        data-tip
                        data-for='helpTip'
                        onClick={(e) => openHelpPopup()}
                      >
                        <svg
                          height='112pt'
                          viewBox='0 0 512 512'
                          width='112pt'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='m277.332031 384c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0' />
                          <path d='m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0' />
                          <path d='m256 314.667969c-8.832031 0-16-7.167969-16-16v-21.546875c0-20.308594 12.886719-38.507813 32.042969-45.269532 25.492187-8.980468 42.625-36.140624 42.625-55.851562 0-32.363281-26.304688-58.667969-58.667969-58.667969s-58.667969 26.304688-58.667969 58.667969c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16c0-49.984375 40.664063-90.667969 90.667969-90.667969s90.667969 40.683594 90.667969 90.667969c0 35.585938-28.097657 73.367188-63.980469 86.039062-6.398438 2.238282-10.6875 8.316407-10.6875 15.101563v21.527344c0 8.832031-7.167969 16-16 16zm0 0' />
                        </svg>
                      </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Loading && <Loader />}
        {props.collectionObject.loading && <Loader />}
        <ReactTooltip
          id='selectReportTip'
          place='top'
          disable={props.toolTipObject.toolTip}
          delayHide={100}
          effect='solid'
        >
          {t('Tooltip.selectReport')}
        </ReactTooltip>

        <ReactTooltip
          id='helpTip'
          place='top'
          disable={props.toolTipObject.toolTip}
          delayHide={100}
          effect='solid'
        >
          {t('Tooltip.help')}
        </ReactTooltip>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const collectionObject = reducerObj.collectionReducers.collectionObject;
  const languageObject = reducerObj.translationReducers.langObject;
  const toolTipObject = reducerObj.toolTipReducers.toolTipObject;
  return { collectionObject, languageObject, toolTipObject };
};

export default compose(
  connect(mapStateToProps, {
    updateCollectionObject,
    resetCollectionObject,
  })
)(ZipList);
