import React, { useState ,useEffect }  from "react";

import Popup from "reactjs-popup";

import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import ReactTooltip from "react-tooltip";

import axios from 'axios';

import { PropTypes } from "prop-types";

import { compose } from "recompose";

import { connect } from "react-redux";

import { Loader } from "../common/Loader/loader";

import { store } from 'react-notifications-component';

import {useTranslation} from "react-i18next";

import {API_SERVER }  from '../constant';

import { updateIndexObject } from "../../actions/IndexAction";

import { updateReportObject } from "../../actions/ReportAction";

import "./modal.css";

import DownloadModal from "./DownloadModal";

import ErrorMessageModal from './ErrorMessageModal';

import { DragSwitch } from 'react-dragswitch'
import 'react-dragswitch/dist/index.css'

const animatedComponents = makeAnimated();

const _ = require('lodash');

function ReportModal(props) {
     const {t, i18n} = useTranslation('common');
     let {showReportPopup, closeReportPopup , reportPopUpData ,userEmail } = props;
     let [Loading, setLoading] = useState(false);
     let [isReportSelected , setisReportSelected] = useState(false);

     let [fileIndexList, setFileIndexList] = useState([]);
     let [userSettingList, setUserSettingList] = useState([]);

     let [reportIndexList, setReportIndexList] = useState([]);
     let [categoryIndexList, setCategoryIndexList] = useState([]);
     let [filterReportJson, setFilterReportJson] = useState([]);
  let [filterUserReportJson, setFilterUserReportJson] = useState([]);
  let [categoryIndexFilteredList, setCategoryIndexFilteredList] = useState([]);


     let [selectedFileIndexItem,setSelectedFileIndexItem]  = useState(null);
     let [selectedReportIndexItem,setSelectedReportIndexItem]   = useState(null);
     let [selectedCategoryIndexItem,setSelectedCategoryIndexItem]  = useState({'value':'ALL','label':'ALL','key':'ALL'});

     let [selectedSortParam ,setSelectedSortParam] = useState("asc");
     let [selectedJsonSortParam ,setSelectedJsonSortParam] = useState("asc");
     let [selectedCategorySortParam ,setSelectedCategorySortParam] = useState("asc");

    let [showDownloadPopup, setShowDownloadPopup] = useState(false);
    let [zipLink, setZipLink] = useState(null);
    let [fileLink, setFileLink] = useState(null);

    let [showErrorMessagedPopup, setShowErrorMessagedPopup] = useState(false);
    let [selectedErrorFile, setSelectedErrorFile] = useState([]);
    let [selectedErrorLabel, setSelectedErrorLabel] = useState([]);
    let [searchReportText, setSearchReportText] = useState('');

     const [showErrorFile, setShowErrorFile] = useState(false)

     const openErrorMessagePopup = () => {
      setShowErrorMessagedPopup((showErrorMessagedPopup = true));
    };
  
    const closeErrorMessagePopup = () => {
      setShowErrorMessagedPopup((showErrorMessagedPopup = false));
    };

      const openDownloadPopup = () => {
        setShowDownloadPopup(showDownloadPopup=true);
      };

      const closeDownloadPopup = () => {
        setShowDownloadPopup(showDownloadPopup=false);
      };

       function onCategoryIndexChange(val)
       {
         setSelectedCategoryIndexItem(selectedCategoryIndexItem=val);
         let subFolderName  =  selectedFileIndexItem.Key;
         let lastIndex = subFolderName.lastIndexOf('/');
         let totalLength = subFolderName.length;
         let subFolderName_modified = subFolderName.substr(lastIndex+1,totalLength);
         let directoryName = subFolderName_modified;
         let sortOrder = selectedJsonSortParam;
         getUploadedFileList(subFolderName,sortOrder,"categoryChange");
       };

     function onFileIndexChange(val)
     {
        console.log("onFileIndexChange",val)
        //commented for dynamic value
        let fileDetails =val.label+'.txt';
         let userEmailID = props.userEmail.toLowerCase()
         let directoryDetails= val.Key;
         const splitString = val.label.split("-");

         axios.post(API_SERVER +`s3-zip-file-get-content`,{'userEmail' :userEmailID ,'directoryName':directoryDetails ,"fileName":fileDetails})
           .then(function (response) {
             if(response.status === 200)
             {
               //const fileData = JSON.stringify(response.data);
               const fileData = response.data;
               console.log("fileData",fileData)
               let result = val.label.match(/ERROR/g);
                if(val.label.match(/ERROR/g)){
                 setShowErrorMessagedPopup((showErrorMessagedPopup = true));
                  setSelectedErrorFile((selectedErrorFile = fileData));
                  setSelectedErrorLabel((selectedErrorLabel = t("ErrorFile."+splitString[0])+"["+splitString[0]+"]"));
                  setCategoryIndexList((categoryIndexList = []));
                  
                  setFileLink((fileLink = selectedFileIndexItem.zip_link.replaceAll('kpis-zip', "error-new-zip")));
                }
                 console.log("fileLink",fileLink)              
                const blob = new Blob([fileData], {type: "text/plain"});
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = selectedReportIndexItem.label +".txt";
                console.log("download url",url)
                link.href = url;
                link.click();
             }
             else
             {
  
             }
           })
           .catch(function (error) {
  
           })
           .then(function () {
           //  setLoading(false);
           });

        setSelectedFileIndexItem(selectedFileIndexItem=val)
         if(selectedFileIndexItem)
         {
           setReportIndexList(reportIndexList=[]);
           setSelectedReportIndexItem(selectedReportIndexItem=null);
           if(val.label.match(/ERROR/g)){
            setisReportSelected(isReportSelected = false);
           }
           else{
            setisReportSelected(isReportSelected = true);
           }
           setSelectedCategoryIndexItem(selectedCategoryIndexItem={'value':'ALL','label':'ALL','key':'ALL'})

           let subFolderName  =  val.Key;
           let lastIndex = subFolderName.lastIndexOf('/');
           let totalLength = subFolderName.length;
           let subFolderName_modified = subFolderName.substr(lastIndex+1,totalLength);
           let directoryName = subFolderName_modified;
           //let sortOrder = selectedSortParam.value;

           props.updateIndexObject({label:val.label,value:val.value,key:val.Key});
           setSelectedJsonSortParam(selectedJsonSortParam="asc");
           getUploadedFileList(subFolderName,'asc',"fileIndexChange");
         }else{
           setReportIndexList(reportIndexList=[]);
           setSelectedReportIndexItem(selectedReportIndexItem=null);
         }
     };
     
     function onFileIndexChange123(val) {
      console.log("onFileIndexChange",val)
      console.log("file_link URL>>",val.file_link)
      
      const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
      const REGION = process.env.REACT_APP_AWS_REGION;
     // let url = `https://` + BUCKET_NAME + `.s3-` + REGION + `.amazonaws.com/`+props.userEmail.toLowerCase() + '/kpis-complete/009file-2023117-153130.txt';
    // let url = "https://prestocharts-e/ankit.prestocharts@gmail.com/kpis-complete/ERROR_LMDERR_001-14csvoutput-20230127-047624.zip" 
     //console.log("kpis-complete url",url)
  
   //   fetch(url).then((r)=>{r.text().then(d=>console.log("File Content",d))})
     // let fileContent = fs.readFileSync("/kpis-complete/009file-2023117-153130.txt");
     //console.log("File Content",fileContent)
  
      //commented for dynamic value
        let fileDetails =val.label+'.txt';
         let userEmailID = props.userEmail.toLowerCase()
         let directoryDetails= val.Key;
         const splitString = val.label.split("-");
         
      //  let directoryDetails= 'ankit.prestocharts@gmail.com/error-new-zip/ERROR_LMDERR_001-err001csvoutput-20230130-23453.zip';
      //  let fileDetails ='ERROR_LMDERR_001-err001csvoutput-20230130-23453.txt';
      //  let userEmailID = "ankit.prestocharts@gmail.com"
         // setLoading(true);
         axios.post(API_SERVER +`s3-zip-file-get-content`,{'userEmail' :userEmailID ,'directoryName':directoryDetails ,"fileName":fileDetails})
           .then(function (response) {
             if(response.status === 200)
             {
               //const fileData = JSON.stringify(response.data);
               const fileData = response.data;
               console.log("fileData",fileData)
               let result = val.label.match(/ERROR/g);
                if(val.label.match(/ERROR/g)){
                 setShowErrorMessagedPopup((showErrorMessagedPopup = true));
                  setSelectedErrorFile((selectedErrorFile = fileData));
                  setSelectedErrorLabel((selectedErrorLabel = t("ErrorFile."+splitString[0])+"["+splitString[0]+"]"));
                  setCategoryIndexList((categoryIndexList = []));
                  
                  setFileLink((fileLink = selectedFileIndexItem.zip_link.replaceAll('kpis-zip', "error-new-zip")));
                }
                
               
                const blob = new Blob([fileData], {type: "text/plain"});
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = selectedReportIndexItem.label +".txt";
                link.href = url;
                link.click();
             }
             else
             {
  
             }
           })
           .catch(function (error) {
  
           })
           .then(function () {
           //  setLoading(false);
           });
      //setShowErrorMessagedPopup((showErrorMessagedPopup = true));
      //setSelectedErrorFile((selectedErrorFile = "Select Error File...."));
      setSelectedFileIndexItem((selectedFileIndexItem = val));
      setSearchReportText((searchReportText = ''));
      setCategoryIndexList((categoryIndexList = []));
      setCategoryIndexFilteredList((categoryIndexFilteredList = []));
      setisReportSelected((isReportSelected = false));
      if (selectedFileIndexItem) {
        setReportIndexList((reportIndexList = []));
        setUserSettingList((userSettingList = val.setting_file_list));
        setSelectedReportIndexItem((selectedReportIndexItem = null));
        setSelectedCategoryIndexItem((selectedCategoryIndexItem = null));
        let newCategoryIndexList = [];
        let newUserIndexList = [];
  
        let promises3 = val.setting_file_list.map(async (item, index) => {
          if (index >= 0) {
            let subFolderName = item;
            let lastIndex = subFolderName.lastIndexOf('/');
            let totalLength = subFolderName.length;
            let subFolderName_modified = subFolderName.substr(
              lastIndex + 1,
              totalLength
            );
            let output =
              subFolderName_modified.substr(
                0,
                subFolderName_modified.lastIndexOf('.')
              ) || subFolderName_modified;
  
            let userItem = {
              value: item,
              label: output,
              key: 'USER_REPORT',
              type: 'blue_color',
            };
            newUserIndexList.push(userItem);
          }
        });
  
        let promises2 = val.zip_file_list.map(async (item, index) => {
          if (index >= 0) {
            let categoryItem = {
              value: item,
              label: item,
              key: 'SELECTED_REPORT',
              type: 'white_color',
            };
            newCategoryIndexList.push(categoryItem);
          }
        });
  
        let oldCategoryIndexList = categoryIndexList;
        let modifiedUserReportIndexList =
          newUserIndexList.concat(newCategoryIndexList);
        let modifiedCategoryIndexList = oldCategoryIndexList.concat(
          modifiedUserReportIndexList
        );
  
        setCategoryIndexList((categoryIndexList = modifiedCategoryIndexList));
        setCategoryIndexFilteredList(
          (categoryIndexFilteredList = modifiedCategoryIndexList)
        );
        setisReportSelected((isReportSelected = true));
      } else {
        setReportIndexList((reportIndexList = []));
        setSelectedReportIndexItem((selectedReportIndexItem = null));
      }
    }

    function sourceFile()
    {
    	if(selectedFileIndexItem != null)
    	 {
          setZipLink(zipLink=selectedFileIndexItem.zip_link);
          setFileLink(fileLink=selectedFileIndexItem.file_link);
          setShowDownloadPopup(showDownloadPopup=true);
    	 }
    }

     function onReportIndexChange(val)
     {
      console.log("onReportIndexChange", val);
       setSelectedReportIndexItem(selectedReportIndexItem=val);
        if(val.type ==="settings")
        {
          props.updateReportObject({label:val.label,value:val.value,key:val.key});
          getFileSettings(val);
        }
        else if(val.type === "delete")
        {
          closeReportPopup();
        }
        else
        {
          props.updateReportObject({label:val.label,value:val.value,key:val.value});
          props.onReportModalCloseAfterApi(selectedFileIndexItem,selectedReportIndexItem);
          closeReportPopup();
        }
     };


     function onSortParamChange(val)
     {
       setSelectedSortParam(selectedSortParam=val);
       setCategoryIndexList(categoryIndexList=[]);
       let directoryName = '';
       let sortOrder = val;

       getUploadedFileList(directoryName,sortOrder,"indexsorting")
     };

     function onCategorySortParamChange(val)
     {
       setSelectedCategorySortParam(selectedCategorySortParam=val);
       if(selectedCategorySortParam != null && selectedFileIndexItem !=null )
        {
         let subFolderName  =  selectedFileIndexItem.Key;
         let lastIndex = subFolderName.lastIndexOf('/');
         let totalLength = subFolderName.length;
         let subFolderName_modified = subFolderName.substr(lastIndex+1,totalLength);
         let directoryName = subFolderName_modified;
         let sortOrder = selectedJsonSortParam;

         getUploadedFileList(subFolderName,sortOrder,"categorysorting")
      }
     };

     function onJsonSortParamChange(val)
     {
       setSelectedJsonSortParam(selectedJsonSortParam=val)

       if(selectedFileIndexItem != null && selectedFileIndexItem !=null )
        {
          let subFolderName  =  selectedFileIndexItem.Key;
          let lastIndex = subFolderName.lastIndexOf('/');
          let totalLength = subFolderName.length;
          let subFolderName_modified = subFolderName.substr(lastIndex+1,totalLength);
          let directoryName = subFolderName_modified;
          let sortOrder = val;
          getUploadedFileList(subFolderName,sortOrder,"reportsorting")
        }
     };


     async function getUploadedFileList(directoryName,sortOrder,operation)
     {
       setLoading(true);
       console.log("getUploadedFileList",directoryName, sortOrder, operation)
       await axios
         .post(API_SERVER + `s3-file-read-complete-folder`, {
           userEmail: userEmail,
           sortOrder: 'asc',
           directoryName:''
         })
         .then(function (response) {
           console.log(response, '=test');
           if (response.status === 200) {
             console.log("getUploadedFileList",response.data.data)

       axios.post(API_SERVER +`s3-zip-file-read-with-category-list-new-format`,{'userEmail' :userEmail ,'directoryName':directoryName,'sortOrder':sortOrder ,'categorySortOrder':selectedCategorySortParam,"filterBy" :selectedCategoryIndexItem,'completeFiles': response.data.data,
       'showErrorFileStatus': showErrorFile})
         .then(function (response1) {
          console.log("response1",response1)
           if(response1.status === 200)
           {
              if(operation === "onRefocus")
              {
                setReportIndexList(reportIndexList=[])
                setReportIndexList(reportIndexList=response1.data.data.report_list_data);
                setCategoryIndexList(categoryIndexList=response1.data.data.category_list_data);
              }else if(operation ==="categorysorting")
              {
                setSelectedReportIndexItem(selectedReportIndexItem=null)
                setReportIndexList(reportIndexList=[])
                setReportIndexList(reportIndexList=response1.data.data.report_list_data);
              }
              else
              {
                if(directoryName === ""){
                  setFileIndexList(fileIndexList=[])
                  setReportIndexList(reportIndexList=[])
                  setisReportSelected(isReportSelected= false);
                  setSelectedFileIndexItem(selectedFileIndexItem=null)
                  setSelectedReportIndexItem(selectedReportIndexItem=null)
                  setFileIndexList(fileIndexList=response1.data.data)
                }else{
                  setReportIndexList(reportIndexList=[])
                  setReportIndexList(reportIndexList=response1.data.data.report_list_data);
                  setCategoryIndexList(categoryIndexList=response1.data.data.category_list_data);
                  setSelectedReportIndexItem(selectedReportIndexItem=null)
                }
             }
           }
           else
           {
             store.addNotification({
               title: "Error",
               message: "Error",
               type: "danger",
               insert: "top",
               container: "top-right",
               animationIn: ["animated", "zoomIn"],
               animationOut: ["animated", "zoomOut"],
               dismiss: {
                 duration: 3000,
                 onScreen: true ,
                 showIcon :true
               }
              });
           }
         })
         .catch(function (error) {
           store.addNotification({
             title: "Error",
             message: "Error",
             type: "danger",
             insert: "top",
             container: "top-right",
             animationIn: ["animated", "zoomIn"],
             animationOut: ["animated", "zoomOut"],
             dismiss: {
               duration: 3000,
               onScreen: true ,
               showIcon :true
             }
            });
         })
         .then(function () {
           setLoading(false);
         });
        }
      })
     };

    /* async function getUploadedFileList(directoryName, sortOrder, operation) {
      setLoading(true);
      console.log("getUploadedFileList",directoryName, sortOrder, operation)
      await axios
        .post(API_SERVER + `s3-file-read-complete-folder`, {
          userEmail: userEmail,
          sortOrder: 'asc',
          directoryName:''
        })
        .then(function (response) {
          console.log(response, '=test');
          if (response.status === 200) {
            console.log("getUploadedFileList",response.data.data)
            //setReportPopUpData((reportPopUpData = response.data.data));
      axios
        .post(API_SERVER + `s3-zip-file-list-with-file-count-new-content`, {
          userEmail: userEmail,
          sortOrder: sortOrder,
          directoryName:'',
          completeFiles: response.data.data,
          showErrorFileStatus: showErrorFile
  
        })
        .then(function (response1) {
          if (response1.status === 200) {
            if(operation === "onRefocus")
            {
              setReportIndexList(reportIndexList=[])
              setReportIndexList(reportIndexList=response.data.data.report_list_data);
              setCategoryIndexList(categoryIndexList=response.data.data.category_list_data);
            }else if(operation ==="categorysorting")
            {
              setSelectedReportIndexItem(selectedReportIndexItem=null)
              setReportIndexList(reportIndexList=[])
              setReportIndexList(reportIndexList=response.data.data.report_list_data);
            }
            else
            {
              if(directoryName === ""){
                setFileIndexList(fileIndexList=[])
                setReportIndexList(reportIndexList=[])
                setisReportSelected(isReportSelected= false);
                setSelectedFileIndexItem(selectedFileIndexItem=null)
                setSelectedReportIndexItem(selectedReportIndexItem=null)
                setFileIndexList(fileIndexList=response.data.data)
              }else{
                setReportIndexList(reportIndexList=[])
                setReportIndexList(reportIndexList=response.data.data.report_list_data);
                setCategoryIndexList(categoryIndexList=response.data.data.category_list_data);
                setSelectedReportIndexItem(selectedReportIndexItem=null)
              }
           }
         } else {
            store.addNotification({
              title: 'Error',
              message: 'Error',
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'zoomIn'],
              animationOut: ['animated', 'zoomOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                showIcon: true,
              },
            });
          }
        })
        .catch(function (error) {
          store.addNotification({
            title: 'Error',
            message: 'Error',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        })
        .then(function () {
          setLoading(false);
        });
  
      }
    })
    }
    */

    async function getFileSettings(val)
     {
       setLoading(true);

      axios.post(API_SERVER +`s3-get-chart-setting-file`,{'userEmail' :userEmail,'indexObject':props.indexObject,'reportObject':val})
         .then(function (response) {
           if(response.status === 200)
           {
               store.addNotification({
                 title: "Success",
                 message: "Please wait while configure selected settings",
                 type: "success",
                 insert: "top",
                 container: "top-right",
                 animationIn: ["animated", "zoomIn"],
                 animationOut: ["animated", "zoomOut"],
                 dismiss: {
                   duration: 3000,
                   onScreen: true ,
                   showIcon :true
                 }
                });
                let loadedSettings = response.data;
                 props.onReportModalCloseAfterApi(selectedFileIndexItem,val,loadedSettings);
                closeReportPopup();
           }
           else
           {
             closeReportPopup();
             store.addNotification({
               title: "Error",
               message: "Error",
               type: "danger",
               insert: "top",
               container: "top-right",
               animationIn: ["animated", "zoomIn"],
               animationOut: ["animated", "zoomOut"],
               dismiss: {
                 duration: 3000,
                 onScreen: true ,
                 showIcon :true
               }
              });
           }
         })
         .catch(function (error) {
           store.addNotification({
             title: "Error",
             message: "Error",
             type: "danger",
             insert: "top",
             container: "top-right",
             animationIn: ["animated", "zoomIn"],
             animationOut: ["animated", "zoomOut"],
             dismiss: {
               duration: 3000,
               onScreen: true ,
               showIcon :true
             }
            });
         })
         .then(function () {
            setLoading(false);
         });
     };

     function checkBackground(param)
     {
       switch(param) {
          case 'settings':
            return '#CAE4FF';
          case 'deleted':
            return '#ffcaca';
          default:
            return 'undefined';
        }
     };

     useEffect(() => {
        if(props.showReportPopup){
          //setSelectedFileIndexItem(selectedFileIndexItem=null);
          //setSelectedReportIndexItem(selectedReportIndexItem=null)
          //setReportIndexList(reportIndexList=[])
          //setSelectedSortParam(selectedSortParam="asc");
          //setSelectedJsonSortParam(selectedJsonSortParam="asc");
          //setShowDeletedFiles(showDeletedFiles = false);
          if(selectedFileIndexItem != null)
          {
            let subFolderName  =  selectedFileIndexItem.Key;
            let sortOrder = selectedJsonSortParam;
            getUploadedFileList(subFolderName,sortOrder,"onRefocus")
          }
        }else{
          //console.log('closed')
        }
     },[props.showReportPopup]);
  //show error files in dropdown on toggle event 
  const handlerErrorFile = () => {
    setShowErrorFile(!showErrorFile)
   
    let directory = '';
      let sortOrder = selectedJsonSortParam;
      console.log("Inside handlerErrorFile ")
      getUploadedFileList(directory, sortOrder, '');
   

  };

  return (
   <React.Fragment>
    <Popup open={showReportPopup} onClose={closeReportPopup} nested>
      <DownloadModal
            showDownloadPopup={showDownloadPopup}
            closeDownloadPopup={closeDownloadPopup}
            zipLink={zipLink}
            fileLink={fileLink}
          />
        <ErrorMessageModal
          showErrorMessagedPopup={showErrorMessagedPopup}
          closeErrorMessagePopup={closeErrorMessagePopup}
          selectedErrorFile={selectedErrorFile}
          selectedErrorLabel={selectedErrorLabel}
          fileLink={fileLink}
        />
       <div className="modal is-active custom-model-popup">
           <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">{t('Modal.SelectSourceFileandReport')}</p>
                <button className="deleteBtn" onClick={props.closeReportPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body">
               <div className="container">
                 <div className="upl-file-row">
                 <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                {console.log("Dropdown option>>",props.reportPopUpData)}
                {console.log("Dropdown option from reportIndexList>>",reportIndexList)}
                <lable style={{"margin-top":"-2px"}}>{t('ChartOption.ShowErrorFile')}</lable> &nbsp; &nbsp; 
                <DragSwitch onColor="#cccccc" offColor="#66bb6a" checked={showErrorFile} onChange={(e) => handlerErrorFile()
                   } />
                  
                </div>
                      <div className="row col-sm-12 col-md-12 col-lg-12 p-0">
                        <div className="col-md-11 col-lg-11 pl-0 custom-filter">
                            <div className="styled-select arrows">
                                <Select
                                  name="FILE"
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                  options={fileIndexList.length > 0 ? fileIndexList:props.reportPopUpData}
                                  onChange={onFileIndexChange}
                                  value={selectedFileIndexItem}
                                  isClearable={false}
                                  placeholder={t('Select.sourceFile')}
                                  isMulti={false}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#CADBFF',
                                      primary: '#CADBFF',
                                    },
                                  })}
                                />
                            </div>
                            <div data-tip data-for="asc" className={selectedSortParam === "asc" ? "sort_asse text-allign-r active-filter" : "sort_asse text-allign-r"} onClick={(e) => onSortParamChange('asc')}>
                                  <i className="fa fa-angle-up"></i>
                              </div>
                                  <div data-tip data-for="desc" className={selectedSortParam === "desc" ? "sort_desc text-allign-r active-filter" : "sort_desc text-allign-r"} onClick={(e) => onSortParamChange('desc')}>
                                  <i className="fa fa-angle-down"></i>
                              </div>
                        </div>
                          <div className="col-md-1 col-lg-1 text-allign-r">
                                <button type="button" className="btn download-btn" onClick={sourceFile}>
                                  <svg id="bold" className="downloadicon" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z"/><path d="m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z"/><path d="m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z"/></svg>
                                </button>
                          </div>
                      </div>
                      <div className="row col-sm-12 col-md-12 col-lg-12 p-0">
                         <div className="col-md-11 col-lg-11 pl-0 custom-filter">
                             <div className="styled-select arrows">
                               <Select
                                 name="FILE"
                                 isDisabled={!isReportSelected}
                                 closeMenuOnSelect={true}
                                 options={categoryIndexList}
                                 onChange={onCategoryIndexChange}
                                 value={selectedCategoryIndexItem}
                                 isClearable={false}
                                 placeholder={t('Select.reportCategory')}
                                 isMulti={false}
                                 theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#CADBFF',
                                      primary: '#CADBFF',
                                    },
                                  })}
                               />
                             </div>
                                <div data-tip data-for="asc" className={selectedCategorySortParam === "asc" ? "sort_asse text-allign-r active-filter" : "sort_asse text-allign-r"} onClick={(e) => onCategorySortParamChange('asc')}>
                                   <i className="fa fa-angle-up"></i>
                               </div>
                                   <div data-tip data-for="desc" className={selectedCategorySortParam === "desc" ? "sort_desc text-allign-r active-filter" : "sort_desc text-allign-r"}  onClick={(e) =>onCategorySortParamChange('desc')}>
                                   <i className="fa fa-angle-down"></i>
                               </div>
                         </div>

                         <div className="col-md-1 col-lg-1">
                         </div>
                      </div>
                     <div className="row col-sm-12 col-md-12 col-lg-12 p-0">
                        <div className="col-md-11 col-lg-11 pl-0 custom-filter">
                            { selectedReportIndexItem === null  ?
                              <ReactTooltip id="selectReportTip" place="bottom" disable={props.toolTipObject.toolTip} delayHide={100 } effect="solid">
                                 {t('Select.selectValueIndropDown')}
                              </ReactTooltip>
                              :null
                            }
                            <div className="styled-select arrows" data-tip data-for="selectReportTip">
                              <Select
                                name="FILE"
                                isDisabled={!isReportSelected}
                                closeMenuOnSelect={true}
                                options={reportIndexList}
                                onChange={onReportIndexChange}
                                value={selectedReportIndexItem}
                                isClearable={false}
                                placeholder={t('Select.report')}
                                isMulti={false}
                                isSearchable={true}
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#CADBFF',
                                    primary: '#CADBFF',
                                  },
                                })}
                                styles={{
                                  option: (styles, { data }) => {
                                    return {
                                      ...styles,
                                      backgroundColor: checkBackground(data.type),
                                      color: "#000000"
                                    };
                                  }
                                }}
                              />
                            </div>
                               <div data-tip data-for="asc" className={selectedJsonSortParam === "asc" ? "sort_asse text-allign-r active-filter" : "sort_asse text-allign-r"} onClick={(e) => onJsonSortParamChange('asc')}>
                                  <i className="fa fa-angle-up"></i>
                              </div>
                                  <div data-tip data-for="desc" className={selectedJsonSortParam === "desc" ? "sort_desc text-allign-r active-filter" : "sort_desc text-allign-r"}  onClick={(e) =>onJsonSortParamChange('desc')}>
                                  <i className="fa fa-angle-down"></i>
                              </div>
                        </div>

                        <div className="col-md-1 col-lg-1">
                              {/*<button type="button" className="btn download-btn" onClick={downloadFile}>
                                <svg id="bold" className="downloadicon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z"/><path d="m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z"/><path d="m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z"/></svg>
                              </button>*/}
                        </div>
                     </div>

                     <div className="col-sm-12 col-md-12 col-lg-12 p-0 reports-dtl">
                        <div className="d-block  p-2 user-save">{t('Select.userSavedreports')}</div>
                        <div className="d-block  p-2 default-report">{t('Select.defaultReports')}</div>
                     </div>
                  </div>
                </div>
            </section>
               </div>
      </div>
      <ReactTooltip id="asc" place="top" disable={props.toolTipObject.toolTip} delayHide={100 } effect="float">
          {t('Select.asc')}
        </ReactTooltip>

        <ReactTooltip id="desc" place="bottom" disable={props.toolTipObject.toolTip} delayHide={100 } effect="float">
           {t('Select.desc')}
        </ReactTooltip>

      {Loading  &&
       <Loader />
    }
    </Popup>
  </React.Fragment>
  );
}



const mapStateToProps = reducerObj => {

  const indexObject = reducerObj.indexReducers.indexObject;
  const reportObject = reducerObj.reportReducers.reportObject;
  const languageObject = reducerObj.translationReducers.langObject;
  const toolTipObject = reducerObj.toolTipReducers.toolTipObject;

  return { indexObject , reportObject ,languageObject,toolTipObject};
};

export default compose(
  connect(mapStateToProps, {
    updateReportObject,
    updateIndexObject
  }),
)(ReportModal);
