import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { useIdleTimer } from 'react-idle-timer';
import ToggleMenu from "../common/toggleMenu/toggle-menu";
import { Link } from 'react-router-dom';
import backImg from "../../assets/images/back.png";
import Header from '../common/Header/innerHeader';
import { RadioChangeEvent } from 'antd';
import { Input, Radio, Space } from 'antd';
import PaypalButton from './paypal';
import axios from 'axios';
import { API_SERVER } from '../constant';
import moment from "moment";
import moment_tz from "moment-timezone";
import CreateQuotationModel from "./CreateQuotationModal";
import { checkUserSession, getLoggedInUserEmail } from '../common/Helper/helper';

import "./pricing.css";

function Plans(props) {
  const { t, i18n } = useTranslation('common');

  let [tabIndex, setTabIndex] = useState(0);
  const [operations, setOperations] = useState([]);
  let [expand, setExpanded] = useState(false);
  const [value, setValue] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [containtedQty, setContaintedQty] = useState(0)
  const [totalSelectedContainedQty, setTotalSelectedContainedQty] = useState(0)
  const [productTax, setProductTax] = useState(0)
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAmountWithoutTax, setTotalAmountWithoutTax] = useState(0);
  const [paypalResponse, setPaypalResponse] = useState(null);
  const [product, setProduct] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [selectedItem, setSelectedItem] = useState(localStorage.getItem("selectedItem"));
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showQuotationPopup, setShowQuotationPopup] = useState(false);
  const [bucket, setBucket] = useState('');
  const [key, setKey] = useState('');
  const [filename, setFilename] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);

  let authToken = localStorage.getItem("CognitoIdentityServiceProvider.3r1b345riifgtn5a6ius8ovt04.b4792b7b-3736-4955-a181-f716fd590b5d.idToken");
  let userDataString = localStorage.getItem("CognitoIdentityServiceProvider.3r1b345riifgtn5a6ius8ovt04.b4792b7b-3736-4955-a181-f716fd590b5d.userData");
  //const email = userData.UserAttributes.find(attr => attr.Name === 'email').Value;
  let email = '';

  if (userDataString) {
    const userData = JSON.parse(userDataString);

    // Find the email attribute
    const emailAttribute = userData.UserAttributes.find(attr => attr.Name === 'email');
    if (emailAttribute) {
      email = emailAttribute.Value;
      console.log(email);
    } else {
      console.log("Email attribute not found.");
    }
  } else {
    console.log("userData not found in local storage.");
  }

  const openQuotationPopup = (e) => {
    setShowQuotationPopup(true);
  }

  const closeQuotationPopup = (e) => {
    setShowQuotationPopup(false);
  }
  async function logOut() {
    await Auth.signOut();
  };
  const handleOnIdle = event => {
    logOut();
  };

  const handleOnActive = event => {
  };

  const handleOnAction = (e) => {
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  const onQtyChange = (event) => {
    let newQuantity = parseInt(event.target.value, 10) || 0;
    setQuantity(newQuantity);
    if (selectedProduct && selectedProduct.list_price && selectedProduct.tax_amount) {
      const price = parseFloat(selectedProduct.list_price);
      const tax = parseFloat(selectedProduct.tax_amount) / 100;
      const totalSelectedContainedQuantity = newQuantity * parseInt(selectedProduct.contained_quanity)
      const calculatedTotal = price * (1 + tax) * newQuantity;
      const totalAmtWithoutTax = price * newQuantity;

      setTotalAmountWithoutTax(totalAmtWithoutTax);
      setTotalSelectedContainedQty(totalSelectedContainedQuantity);
      setProductTax((tax * newQuantity).toFixed(2));
      setTotal(calculatedTotal.toFixed(2));
    } else {
      setTotal(0);
    }
  };
  const selectedProductFunc = (event) => {
    setSelectedProduct(product.find(prod => prod.id === parseInt(event.target.value, 10)));    
    setQuantity(0);
    setProductTax(0);
    setTotal(0);
  }
  useEffect(() => {
    checkUserSession();
    
  });
  useEffect(() => {
    if (selectedProduct) {
      const price1 = parseFloat(selectedProduct.list_price);
      setProductPrice(price1.toFixed(2));
      setContaintedQty(selectedProduct.contained_quanity);
    }
  }, [selectedProduct])
  const onChange = async (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
    // const authToken = localStorage.getItem("CognitoIdentityServiceProvider.3r1b345riifgtn5a6ius8ovt04.b4792b7b-3736-4955-a181-f716fd590b5d.idToken");

    // await axios.post(API_SERVER+`odooProductList`, {
    //   'action': 'getproductlist',
    //   'domain': 'prestocharts.com',
    //   'authToken': authToken
    // }, {
    //   headers: {
    //     'Authorization': `Bearer ${authToken}`,
    //     'Content-Type': 'application/json'
    //   }
    // })
    //   .then(function (response) {
    //     console.log("Product list",response.data)
    //     setProductList(response.data);

    //   })
    //   .catch(function (error) {

    //   })
    //   .then(function () {

    //   });

  };
  async function sendTransactionDetails(paypalResponse) {
    await axios.post(API_SERVER + `sendTransactionDetails`, {
      'email': email,
      'paypalResponse': paypalResponse,
      'product_id': selectedProduct?.id,
      'productExpirationTimeframe': selectedProduct?.expiration_timeframe,
      'quantity': quantity,
      'amount': total,
      'unit_of_measure': selectedProduct?.unit_of_measure,
      'contained_quanity': selectedProduct?.contained_quanity,
      'authToken': authToken
    }, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        console.log("createonhand", response.data)
        if (response.data.return_code == 0) {
          setShowQuotationPopup(true);
          console.log("Invoice>>", response.data.invoice.s3path);
          setBucket(response.data.invoice.bucket_name)
          setKey(response.data.invoice.s3path)
          setFilename(response.data.invoice.filename)
          localStorage.setItem("outputfilepath", response.data.outputfilepath);
          localStorage.setItem("bucket", response.data.invoice.bucket_name);
        }
        //setProduct(response.data);

      })
      .catch(function (error) {
        console.error("Error:", error.message);
      })
      .then(function () {

      });
  }
  async function getProductList() {

    await axios.post(API_SERVER + `odooProductList`, {
      'action': 'getproductlist',
      'domain': 'prestocharts.com',
      'authToken': authToken,
      'email': email,
      'company': false,
      'companyname': "",
      'region': ""
    }, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        console.log("Product list", response.data)
        setProduct(response.data);
        
        if (response.data.length > 0) {
          setSelectedProduct(response.data[0]);
        }

      })
      .catch(function (error) {
        console.error("Error:", error.message);
      })
      .then(function () {

      });

  };

  function tabChange(index, lastIndex) {
    setTabIndex(index);
  };

  function toggleSidebar() {

  };

  function handleSave(img) {
    console.log(img.dataUrl);
  }

  const handleLogo = () => {
    window.location.replace("/home");
  }

  function timestampToDatetimeInputString(timestamp) {
    const date = new Date((timestamp + _getTimeZoneOffsetInMs()));
    // slice(0, 19) includes seconds
    return date.toISOString().slice(0, 19);
  }

  function _getTimeZoneOffsetInMs() {
    return new Date().getTimezoneOffset() * -60 * 1000;
  }

  let currentTime = timestampToDatetimeInputString(Date.now());
  console.log("currentTime", currentTime)
  console.log("systemTime", new Date().toString())
  let timestamp = moment().format('YYYY-MM-D  hh:mm:ss a');
  console.log("moment time", moment_tz.tz.guess());

  const handleMenuItemClick = (item) => {
    alert(item.key)
    setSelectedItem(item.key);
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  //   const downloadFile = async () => {
  //     try {
  //         // Request the pre-signed URL from your backend
  //         const response = await axios.get('http://localhost:3001/generate-presigned-url');
  //         const { url } = response.data;

  //         // Trigger file download
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'file.ext'); // Optional: specify the downloaded file name
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();
  //     } catch (error) {
  //         console.error('Error downloading the file', error);
  //     }
  // };
  const downloadFile = async () => {
    try {
      // Request the pre-signed URL from your backend
      let s3Path = key;
      let replaceName = bucket + '/';
      let newKeyPath = s3Path.replace(replaceName, '');
      console.log("newFilename", newKeyPath, bucket, filename);
      const response = await axios.get(API_SERVER + `generate-presigned-url`, {
        params: {
          bucket: bucket,
          key: newKeyPath,
          filename: filename,
        },
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
      const { url } = response.data;
      //window.open(url, '_blank');

      // Trigger file download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename || 'downloaded-file'); // Optional: specify the downloaded file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };
  // const previewPdf = async () => {
  //   try {
  //     // Request the pre-signed URL from your backend
  //     let s3Path = key;
  //     let replaceName = bucket + '/';
  //     let newKeyPath = s3Path.replace(replaceName, '');
  //     console.log("newFilename", newKeyPath, bucket, filename);
  //     const response = await axios.get(API_SERVER + `generate-presigned-url`, {
  //       params: {
  //         bucket: bucket,
  //         key: newKeyPath,
  //         filename: filename,
  //       },
  //       headers: {
  //         'Authorization': `Bearer ${authToken}`,
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     const { url } = response.data;

  //     // Set the PDF URL for preview
  //     //setPdfUrl(url);
  //     window.open(url, '_blank', 'width=800,height=600');
  //   } catch (error) {
  //     console.error('Error generating the preview URL', error);
  //   }
  // };
  useEffect(() => {
    console.log("paypalResponse", paypalResponse);
    if (paypalResponse && paypalResponse.status === 'COMPLETED') {
      console.log("paypal transaction completed")
      sendTransactionDetails(paypalResponse);
    }
  }, [paypalResponse]);

  useEffect(() => {
    getProductList();    
  }, []);
  return (
    <React.Fragment>
      <CreateQuotationModel
        setShowQuotationPopup={showQuotationPopup}
        closeQuotationPopup={closeQuotationPopup}
        productQty={quantity}
        productprice={total}
      />
      <Header
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handleMenuItemClick={handleMenuItemClick}
      />
      <div id="wrapper" className={expand ? "menu-active" : "menu"}>

        <div id="page-content-wrapper" className="page-content-wrapper-profile">
          <h3 className="hed-h3">Purchase Token</h3>
          
          {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {Array.isArray(product) && product.length > 0 ? (
              product.map(prod => (
                <div key={prod.id} className="price-paln">
                  <p><strong>ID:</strong> {prod.id}</p>
                  <p><strong>Name:</strong> {prod.product_name}</p>
                  <p><strong>Quantity Available:</strong> {prod.qty_available}</p>
                  <p><strong>Containted Quantity:</strong> {prod.contained_quanity}</p>
                  <p><strong>List Price:</strong> ${prod.list_price}</p>
                  <p><strong>Tax String:</strong> {prod.tax_string}</p>                  
                  <p><strong>Tax Amount:</strong> {prod.tax_amount}%</p>
                  <p><strong>Description:</strong> {prod.product_description}</p>
                  <p><strong>Validity:</strong> {prod.expiration_timeframe} {prod.expiration_timeframe > 1 ? "Months" : "Month"}</p>
                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </div> */}
          
          <div className="product-section">
            {/* <div className="input-group total-amount">
              <label htmlFor="totalAmount">Plan Type</label>
              <Radio.Group className="ant-radio-group" onChange={onChange} value={value}>
                <Radio className="ant-radio-wrapper" value={1}>One time token purchase</Radio>
                <Radio className="ant-radio-wrapper" value={2}>Subscribe to multi file processing</Radio>
              </Radio.Group>
            </div> */}
            <div className="input-group total-amount">
              <label htmlFor="totalAmount">Product</label>
              <select
                className="product-select"
                value={selectedProduct ? selectedProduct.id : ''}               
                onChange={selectedProductFunc}
              >
                <option value="">Select a product</option>
                {Array.isArray(product) && product.map(product1 => (
                  <option key={product1.id} value={product1.id}>{product1.product_name}</option>
                ))}
              </select>
            </div>

            {/* {(value === 1 || value === 2) && ( */}
              <>
                <div className="input-group total-amount">
                  <label htmlFor="productPrice">Containted Quantity</label>
                  <Input
                    type="text"
                    id="containtedQty"
                    name="containtedQty"
                    value={containtedQty}
                    readOnly /> &nbsp; &nbsp; &nbsp; &nbsp;<label htmlFor="productPrice">Unit of Measure:</label>{selectedProduct?.unit_of_measure}
                </div>
                <div className="input-group total-amount">
                  <label htmlFor="productPrice">Product Price ($)</label>
                  <Input
                    type="text"
                    id="productPrice"
                    name="productPrice"
                    value={productPrice}
                    readOnly />
                </div>
                <div className="input-group total-amount">
                  <label htmlFor="tokenQty">Add Token Quantity</label>
                  <Input
                    type="number"
                    id="tokenQty"
                    name="tokenQty"
                    value={quantity}
                    onChange={onQtyChange} />
                  &nbsp;&nbsp;&nbsp;&nbsp;<label htmlFor="tokenQty">Total Selected Quantity</label>
                  <Input
                    type="number"
                    id="totalSelectedContainedQty"
                    name="totalSelectedContainedQty"
                    value={totalSelectedContainedQty}
                  />
                </div>
                
                <div className="input-group total-amount">
                  <label htmlFor="tokenQty">Sub Total ($)</label>
                  <Input
                    type="number"
                    id="totalAmountWithoutTax"
                    name="totalAmountWithoutTax"
                    value={totalAmountWithoutTax}
                  />
                </div>
                <div className="input-group total-amount">
                  <label htmlFor="productTax">Tax Amount ($)</label>
                  <Input
                    type="text"
                    id="productTax"
                    name="productTax"
                    value={productTax}
                    readOnly />
                </div>
                <div className="input-group total-amount">
                  <label htmlFor="totalAmount">Total Amount ($)</label>
                  <Input
                    type="text"
                    id="totalAmount"
                    name="totalAmount"
                    value={total}
                    readOnly /> <h6 style={{margin: '3px 0 0 10px'}}>(including all the taxes)</h6>
                </div>
                </>
            {/* )} */}

            <div className="paypal-button">
              <PaypalButton className="paypal-button"
                productId={selectedProduct?.id}
                productQty={quantity}
                productprice={total}
                paypalResponse={paypalResponse}
                setPaypalResponse={setPaypalResponse}
              />
            </div>
            {/* <button onClick={previewPdf}>Preview PDF</button> */}
            {/* <button onClick={downloadFile}>Download File</button> */}
            {/* {pdfUrl && (
              <div>                
                <iframe
                  src={pdfUrl}
                  title="PDF Preview"
                  width="600"
                  height="400"
                />
                <div>
                  <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    <button>Download PDF</button>
                  </a>
                </div>
              </div>
            )} */}
          </div>
        </div>

      </div>
    </React.Fragment>
  )
};


const mapStateToProps = reducerObj => {
  const themeObject = reducerObj.themeReducers.themeObject;
  const languageObject = reducerObj.translationReducers.langObject;
  return { themeObject, languageObject };
};

export default compose(
  connect(mapStateToProps, {

  }),
)(Plans);
