import React, { useState, useEffect } from 'react';
import InvoiceTable from './InvoiceTable';
import { getUserOutputFileData, getLoggedInUserEmail, getInvoiceListFromS3 } from '../common/Helper/helper';
import axios from "axios";
import { API_SERVER } from "../constant";

const App = () => {
 

  // const [invoices, setInvoices] = useState([
  //   { id: 1, name: 'Invoice 001', date: '2024-01-01' },
  //   { id: 2, name: 'Invoice 002', date: '2024-02-15' },
  //   // Add more invoices as needed
  // ]);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      const invoiceData = await getInvoiceListFromS3();
      console.log("invoiceData", invoiceData);
      setInvoices(invoiceData);
    };

    fetchInvoiceData();
  }, []);
  const handleDelete = (id) => {
    setInvoices(invoices.filter(invoice => invoice.id !== id));
  };
  const handleDownload = async (filename, folderpath) => {
    console.log("select file for downlaod", filename, folderpath)
    const userInfo = await getLoggedInUserEmail();
    const newfolderpath = folderpath.replace(/^[^/]+\//, "");
    try {
      const response = await axios.post(API_SERVER + `download`,
        { folderpath: folderpath, filename: filename, email: userInfo.email },
        {
          responseType: "blob", // Important: Ensures response is treated as a binary blob
        }
      );

      // Create a download link and trigger it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }


  return (
    <div className="container">
      <h2 className="h2-page-title">Invoice List</h2>
      <InvoiceTable invoices={invoices} onDelete={handleDelete} onDownload={handleDownload} />
    </div>
  );
};

export default App;
